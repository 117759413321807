import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Alert, Card, Col, Modal, Row } from "antd";

import { useKeyDetailsMutation } from "../../../../../redux/services/cmcApi";



const KeyDetailsComp = ({ planInfoModalOpen, setplanInfoModalOpen, cmcKey }) => {
   const [keyDetails] = useKeyDetailsMutation();
   const { data, status } = useSelector((data) => data?.addcmcSlice?.keyDetails);

   useSelector((state) => state)
   const usage = data?.usage;
   const plan = data?.plan;
   const notice = status?.notice;


   useEffect(() => {
      planInfoModalOpen &&
         keyDetails({
            cmc_key: cmcKey,
         });
   }, [planInfoModalOpen]);

   const handleCancel = () => {
      setplanInfoModalOpen(false);
   };

   const title = `Key: ${cmcKey}`;

   return (
      <>
         <Modal
            title={title}
            open={planInfoModalOpen}
            onCancel={handleCancel}
            footer={null}
            width={"55rem"}
            centered={true}
         >
            <br />
            <Row className="cmcKeyInfo" gutter={16}>
               <Col span={12}>
                  <Card title="Usage" bordered={false} style={{ height: "300px" }}>
                     <h4>
                        Current Day :{" "}
                        <span className="cmc-keyDetails">
                           {usage?.current_day?.credits_used}
                        </span>
                     </h4>
                     <h4>
                        Current Minute :{" "}
                        <span className="cmc-keyDetails">
                           {usage?.current_minute?.requests_left}
                        </span>
                     </h4>
                     <h4>
                        Current Minute Left{" "}
                        <span className="cmc-keyDetails">
                           {usage?.current_minute?.requests_left}
                        </span>
                     </h4>
                     <h4>
                        Current Month :{" "}
                        <span className="cmc-keyDetails">
                           {usage?.current_month?.credits_left}
                        </span>
                     </h4>

                     <h4>
                        Current Month Left{" "}
                        <span className="cmc-keyDetails">
                           {usage?.current_month?.credits_left}
                        </span>
                     </h4>
                  </Card>
               </Col>
               <Col span={12}>
                  <Card title="Plans" bordered={false} style={{ height: "300px" }}>
                     <h4>
                        Monthly Limit :{" "}
                        <span className="cmc-keyDetails">{plan?.credit_limit_monthly}</span>
                     </h4>
                     <h4>
                        Monthly Reset :{" "}
                        <span className="cmc-keyDetails">
                           {plan?.credit_limit_monthly_reset}
                        </span>
                     </h4>
                     <h4>
                        Rate Limit Minute :{" "}
                        <span className="cmc-keyDetails">{plan?.rate_limit_minute}</span>
                     </h4>
                  </Card>
               </Col>

               {(notice) && (
                  <Col span={24}>
                     <Alert
                        message="Notice"
                        description={notice}
                        type="error"
                        showIcon={false}
                     />
                  </Col>
               )}
            </Row>
         </Modal>
      </>


   );
};
export default KeyDetailsComp;
