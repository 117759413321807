import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as device from "react-device-detect";
import { v4 as uuid } from "uuid";

import { Button, Col, Form, Input, Row } from "antd";
import { LockOutlined } from "@ant-design/icons";

import { useResetPassMutation } from "../../redux/services/loginApi";

const ResetPassPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [resetPass, {}] = useResetPassMutation();
  const [deviceInfo, setDeviceInfo] = useState(null);

  const getDeviceId = async () => {
    const uniqeId = localStorage.getItem("device_id");
    if (!uniqeId) {
      localStorage.setItem("device_id", uuid());
    }

    setDeviceInfo({
      browserName: device.browserName,
      browserVersion: device.browserVersion,
      osName: device.osName,
      osVersion: device.osVersion,
      device_id: uniqeId,
      device_type: device.isDesktop ? "desktop" : "mobile",
    });
  };

  useEffect(() => {
    getDeviceId();
  }, []);

  const resetPassToken = location.pathname?.split("reset-pass/")[1];

  const ResetPassSubmit = async (ResetPassValue) => {
    ResetPassValue = {
      ...ResetPassValue,
      token: resetPassToken,
    };
    resetPass(ResetPassValue).then((data) => {
      if (data?.data?.status) {
        navigate(`/login`);
      }
    });
  };

  return (
    <>
      <Row style={{ marginTop: "10vh", minHeight: "65vh" }}>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 20, offset: 2 }}
          lg={{ span: 8, offset: 8 }}
          xl={{ span: 8, offset: 8 }}
          xxl={{ span: 8, offset: 8 }}
        >
          <Row
            className="loginInnerSec"
            justify="space-around"
            align="middle"
            style={{
              minHeight: "100%",
              boxShadow: "rgb(153, 153, 153, 0.5) 0px 0px 40px 10px",
              borderRadius: "22px",
            }}
          >
            <Col
              xs={{ span: 20, offset: 0 }}
              sm={{ span: 20, offset: 0 }}
              md={{ span: 20, offset: 0 }}
              lg={{ span: 20, offset: 0 }}
              xl={{ span: 20, offset: 0 }}
              xxl={{ span: 20, offset: 0 }}
            >
              <Form
                name="normal_ResetPass"
                className="ResetPass-form"
                initialValues={{ remember: true }}
                onFinish={ResetPassSubmit}
              >
                <Form.Item
                  style={{
                    marginBottom: "40px",
                  }}
                >
                  <h1> Reset Password </h1>
                  <span> Enter a new password for your account. </span>
                </Form.Item>

                <Form.Item
                  name="new_password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password",
                    },
                    {
                      min: 8,
                      message: "Password must have a minimum length of 8",
                    },
                    {
                      pattern: new RegExp(
                        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
                      ),
                      message:
                        "Password must contain at least one lowercase letter, uppercase letter, number, and special character",
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="New Password"
                  />
                </Form.Item>
                <Form.Item
                  name="confirm_password"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("new_password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The new password that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    size="large"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Confirm Password"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="ResetPass-form-button"
                  >
                    Reset my Password
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default ResetPassPage;
