import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";

const VenderFilter = ({
  searchKeywordFn,
  resetKeywordFn,
  setSelectedValue,
  selectedValue,
  pageSize,
  currentPage,
}) => {
  const [search, setSearch] = useState("");

  const values = [
    {
      value: "active",
      label: "active",
    },
    {
      value: "declined",
      label: "decline",
    },
    {
      value: "pending",
      label: "pending",
    },
    {
      value: "blocked",
      label: "block",
    },
  ];

  const onFinish = () => {
    if (selectedValue?.length > 0 || search !== "") {
      searchKeywordFn(search);
    }
  };
  const onReset = () => {
    if (selectedValue?.length > 0 || search !== "") {
      setSearch("");
      setSelectedValue([]);
      resetKeywordFn();
    }
    if (currentPage > 1 || pageSize > 10) {
      resetKeywordFn();
    }
  };

  function handleChange(selectedStatus) {
    setSelectedValue(selectedStatus);
  }

  return (
    <>
      <Form name="control-ref" onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col xs={24} sm={12} md={12} lg={4}>
            <Input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search keyword"
            />
          </Col>
          <Col xs={24} sm={12} md={12} lg={4}>
            <Select
              showSearch
              value={selectedValue}
              placeholder={"Status"}
              onChange={handleChange}
              options={values}
              style={{ width: "100%" }}
            />
          </Col>
          <Col md={6}>
            <Form.Item className="searchresetbtn" >
              <Button type="primary" htmlType="submit">
                Search
              </Button>

              <Button htmlType="button" onClick={onReset} type="primary" danger>
                Reset
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default VenderFilter;
