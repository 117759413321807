import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Breadcrumb, Button, Card, Col, Collapse, Row, Table } from "antd";
import Chart from 'chart.js/auto';
import moment from "moment";

import { useVenderInfoMutation } from "../../../redux/services/venderApi";

import UpgradeVenderPlan from "./component/upgradePlan.comp";
import ApiHitsUsageIcon from "./component/apiHitsUsageIcon.comp";
import ActionButtonsVenders from "./component/actionBtns.comp";
import UpdatePlanDate from "./component/updatePlanDate.comp";
import SettingsBtn from "./component/settingsBtn.comp";

import VenderCreditUsageChart from "./component/creditUsageChart.comp";

const VenderDetailsPage = () => {
   const location = useLocation();
   const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);

   const [fetchVenderInfo, { data: getVenderInfo }] = useVenderInfoMutation();

   const [graphData, setGraphData] = useState({
      label: [],
      value: [],
   });
   const [getVenderData, setVenderData] = useState();


   const [getPlanValidityDate, setPlanValidityDate] = useState();

   const chartRef = useRef(null);
   let chartInstance = useRef(null);

   const venderId = location.pathname?.split("vender-details/")[1];

   const openUpgradePlanModal = () => {
      setShowUpgradePlanModal(true)
   }

   const apiHistoryColumns = [{
      title: "Last 100 API Request",
      dataIndex: "lastrequest",
      key: "lastrequest",
      render: (text, record) => {
         return (
            <div>
               {!record?.status ? (
                  <>
                     <span style={{ color: "red" }}>HTTP 400 </span>
                     <span style={{ color: "GrayText" }}>
                        {`- IP ${record?.lastLoginIp}`}
                     </span>
                  </>
               ) : (
                  <>
                     <span style={{ color: "green" }}>HTTP 200 </span>
                     <span style={{ color: "GrayText" }}>
                        {`- IP ${record?.lastLoginIp}`}
                     </span>
                  </>
               )}

               <br />
               <div>{record?.url}</div>
            </div>
         );
      },
   }, {
      title: <span className="timestamp">Timestamp</span>,
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text, record) => {
         return (
            <>
               <span>{new Date(record?.created_at).toLocaleDateString()}</span>,{" "}
               <span>{new Date(record?.created_at).toLocaleTimeString()}</span>
            </>
         );
      },
   }, {
      title: "Credit Count",
      dataIndex: "creditcount",
      key: "creditcount",
      render: (text, record) => {
         return record?.status == false ? 0 : record?.credit_count;
      }
   }];

   const planHistoryColumns = [{
      title: 'Plan Title',
      dataIndex: "api_hits_plan",
      key: "api_hits_plan",
      render: (text, record) => {
         return record?.api_hits_plan?.title;
      },
   }, {
      title: 'Plan Hits',
      dataIndex: "api_hits_plan",
      key: "api_hits_plan",
      render: (text, record) => {
         return (
            <>
               {/* <p><b> Hits/ Minute :</b> {record?.api_hits_plan?.minute_hits}</p>
               <p><b> Hits/ Day:</b> {record?.api_hits_plan?.daily_hits}</p>
               <p><b> Hits/ Week:</b> {record?.api_hits_plan?.weekly_hits}</p> */}
               <p><b> Hits/ Month:</b> {record?.api_hits_plan?.monthly_hits}</p>
            </>
         );
      },
   }, {
      title: "Purchased Date",
      dataIndex: "created_at",
      key: "purchasedDate",
      render: (text, record) => {
         return new Date(record?.created_at).toLocaleDateString()
      }
   }, {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
         return (
            record?.status == 'change' ? record?.status + ' (' + record?.message + ')' : record?.status
         )
      }
   }];

   const collapseItems = [{
      key: 'purchasePlanHistory',
      label: 'Recent Purchase Plan History',
      children: <Table
         columns={planHistoryColumns}
         dataSource={getVenderData?.plan_history}
         pagination={false}
      />,
   }, {
      key: 'usedApis',
      label: 'Recent Used Apis',
      children: <Table
         columns={apiHistoryColumns}
         dataSource={getVenderData?.api_logs}
         pagination={false}
      />,
   }];

   const fetcjVenderInfoData = async () => {
      const resp = await fetchVenderInfo({
         id: venderId
      });
      const venderData = resp?.data?.data
      const planValidityDate = venderData?.vender?.plan_valid_up_to ? moment.utc(venderData?.vender?.plan_valid_up_to).format("DD-MMM-YYYY - HH:mm:ss") : "none"
      setVenderData(venderData)
      setPlanValidityDate(planValidityDate);
   }

   useEffect(() => {
      const labels = [];
      const values = [];
      getVenderData?.graphData?.forEach((item) => {
         labels.push(item.label);
         values.push(item?.credit_count || 0);
      });
      setGraphData({ label: labels, value: values });
   }, [getVenderData?.graphData]);

   useEffect(() => {
      if (chartRef && chartRef.current && graphData.label.length > 0) {
         if (chartInstance.current) {
            chartInstance.current.destroy(); // Destroy existing chart before creating a new one
         }

         chartInstance.current = new Chart(chartRef.current, {
            type: 'bar',
            data: {
               labels: graphData.label,
               datasets: [
                  {
                     data: graphData.value,
                     backgroundColor: 'rgb(0,149,255)',
                     borderColor: 'rgb(0,149,255)',
                     barThickness: 30,
                  },
               ],
            },
            options: {
               plugins: {
                  legend: {
                     display: false,
                  },
               },
               scales: {
                  y: {
                     beginAtZero: true,
                     ticks: {               //  Used to make y-axis in integer
                        callback: function (value) {
                           return Number.isInteger(value) ? value : null;
                        },
                     },
                  },
               },
            },
         });
      }
   }, [graphData]);

   useEffect(() => {
      fetcjVenderInfoData()
   }, [])

   return (
      <>
         <Card style={{ marginBottom: '5px' }}>
            <Breadcrumb items={[
               { title: <Link to="/dashboard" >Dashboard</Link> },
               { title: <Link to="/venders-list">Venders</Link> },
               { title: getVenderData?.vender?.email }
            ]} />
         </Card>

         <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={24} lg={16}>
               <Col span={24}>
                  <Card bordered={true} className="actionButtonsVenderCard" >
                     <b> {getVenderData?.vender?.name} </b>
                  </Card>
               </Col>
               <Col span={24}>
                  <VenderCreditUsageChart vender_id={venderId} />
               </Col>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
               <Row gutter={[10, 5]}>
                  {!(getVenderData?.vender?.status == "pending" || getVenderData?.vender?.status == "declined") && (
                     <Col span={24}>
                        <Card bordered={true} className="actionButtonsVenderCard" >
                           <ActionButtonsVenders
                              vender={getVenderData?.vender}
                              fetchVenderInfo={fetchVenderInfo}
                           />
                        </Card>
                     </Col>
                  )}
                  <Col span={24}>
                     <Card title="Plan Valid upto" bordered={true} className="planValidUptoCard" extra={
                        <UpdatePlanDate
                           vender={getVenderData?.vender}
                           setPlanValidityDate={setPlanValidityDate}
                           getPlanValidityDate={getPlanValidityDate}
                        />
                     } >
                        {getPlanValidityDate}
                     </Card>

                  </Col>
                  <Col span={24}>
                     <Card title="Current Plan" bordered={true} extra={
                        <Button
                           key="upgradePlanBtn"
                           type="primary"
                           onClick={() => openUpgradePlanModal()}
                        >
                           Upgrade Plan
                        </Button>
                     }>

                        <>
                           <p><b> Title :</b> {getVenderData?.vender?.api_hits_plan?.title} </p>
                           <p><b> Price:</b> {getVenderData?.vender?.api_hits_plan?.price}</p>
                           <p><b> Next Re-new on:</b> {getVenderData?.vender?.api_hits_plan?.renew_date}</p>

                           <p> <ApiHitsUsageIcon
                              title='Hits/ Month'
                              monthlyHits={getVenderData?.vender?.api_hits_usage?.monthly_hits || 0}
                              planHits={getVenderData?.vender?.api_hits_plan?.monthly_hits || 0}
                           />
                           </p>
                        </>
                     </Card>
                  </Col>

                  <Col span={24}>
                     <Card title="Settings" bordered={true}>
                        <SettingsBtn
                           vender_id={getVenderData?.vender?.id}
                           btn_key={'allow_add_custom_token'}
                           btn_value={getVenderData?.vender?.settings?.allow_add_custom_token}
                        />
                     </Card>
                  </Col>
               </Row>
            </Col >
         </Row >


         <Row gutter={[20, 20]} >
            <Col span={24}>
               <Collapse items={collapseItems} defaultActiveKey={['1']} />
            </Col>
         </Row>



         {/*  */}

         <UpgradeVenderPlan
            showUpgradePlanModal={showUpgradePlanModal}
            setShowUpgradePlanModal={setShowUpgradePlanModal}
            venderId={venderId}
            currentPlan={getVenderData?.vender?.api_hits_plan}
            fetchVenderInfo={fetchVenderInfo}
         />
      </>
   );
};
export default VenderDetailsPage;
