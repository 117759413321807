import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Collapse, Space } from "antd";
import { toast } from "react-toastify";

import {
  useSyncCmcCoinsMutation,
  useSyncCoinsInfoMutation,
  useSyncCoinsListMutation,
} from "../../../redux/services/coinsApi";

const CoinsSyncPage = () => {
  const [loadings, setLoadings] = useState([]);
  const [syncCoinsList] = useSyncCoinsListMutation();
  const [SyncCmcCoins] = useSyncCmcCoinsMutation();

  const [syncCoinsInfo] = useSyncCoinsInfoMutation();

  const enterLoading = (index) => {
    // setLoadings((prevLoadings) => {
    //   const newLoadings = [...prevLoadings];
    //   newLoadings[index] = true;
    //   return newLoadings;
    // });
    // setTimeout(() => {
    //   setLoadings((prevLoadings) => {
    //     const newLoadings = [...prevLoadings];
    //     newLoadings[index] = false;
    //     return newLoadings;
    //   });
    // }, 6000);
  };

  const SyancCmcCoins = (url) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[url] = true;
      return newLoadings;
    });

    const values = {
      url: `cmc/${url}`,
    };

    SyncCmcCoins(values).then((data) => {
      if (data?.data?.status) {
        toast.success(data?.data?.message, { autoClose: 2000 });
      } else {
        toast.error(data?.data?.message, { autoClose: 2000 });
      }

      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[url] = false;
        return newLoadings;
      });
    });
  };

  useEffect(() => { }, []);

  const allListSyncCoins = () => {
    syncCoinsList();
  };

  const coinsInfo = () => {
    syncCoinsInfo();
  };

  return (
    <>
      <Card>
        <Breadcrumb
          items={[
            { title: "Dashboard", },
            { title: "Coins", },
            { title: "Sync", },
          ]}
        />
      </Card>

      <Card>
        <Collapse
          size="large"
          defaultActiveKey="cmc"
          items={[
            {
              key: "cmc",
              label: "CMC",
              children: (
                <>
                  <Space wrap>
                    <Button
                      type="primary"
                      loading={loadings["syncCoins"]}
                      onClick={() => allListSyncCoins()}
                    >
                      Sync All Coin List
                    </Button>
                    <Button
                      type="primary"
                      loading={loadings["syncInfo"]}
                      onClick={() => coinsInfo("syncInfo")}
                    >
                      Sync Coin's info
                    </Button>
                    {/* <Button type="primary" loading={loadings['marketData']} onClick={() => SyancCmcCoins('marketData')}>Market Data</Button> */}
                  </Space>
                </>
              ),
            },
          ]}
        />
      </Card>
    </>
  );
};
export default CoinsSyncPage;
