import { Button, DatePicker, Divider, Form, Input, Modal, Select, Space } from "antd";
import { useEffect, useState } from "react";

import moment from "moment";

import { usePlansListRecordMutation } from "../../../../redux/services/plansListApi";
import { useAddNewVenderMutation } from "../../../../redux/services/venderApi";

const AddNewVender = ({
   fetchVenderList,
   selectedValue,
   currentPage,
   pageSize,
}) => {
   const [plansListRecord, { data: getPlansList }] = usePlansListRecordMutation();
   const [addNewVenderApi] = useAddNewVenderMutation();

   const [addNewVenderForm] = Form.useForm();

   const [getAddNewVenderModelOpen, setAddNewVenderModelOpen] = useState(false);

   const options = []

   if (getPlansList?.data?.rows && Array.isArray(getPlansList?.data?.rows)) {
      for (let plan of getPlansList?.data?.rows) {
         options.push({
            value: plan.id,
            label: plan.title
         })
      }
   }

   /** Function to disable past dates */
   const disabledDate = (current) => {
      /** Can not select days before today and today */
      return current && current < moment().startOf('day');
   };

   const onResetAddNewVenderFrom = () => {
      addNewVenderForm.resetFields();
   };

   const onFinishAddNewVender = (values) => {
      values = {
         ...values,
         plan_valid_up_to: values.plan_valid_up_to.format('YYYY-MM-DD')
      }

      addNewVenderApi(values).then((resp) => {
         resp?.data?.status && resp?.data?.message && fetchVenderList &&
            fetchVenderList({
               status: selectedValue,
               keyword: "",
               page: currentPage,
               limit: pageSize,
            });

         /**  */

         if (resp?.data?.status && resp?.data?.message) {
            setAddNewVenderModelOpen(false);
            onResetAddNewVenderFrom();
         }
      });

   }

   useEffect(() => {
      plansListRecord();
   }, [])

   return (
      <>
         <Button
            key="addNewVender"
            type="primary"
            onClick={() => {
               setAddNewVenderModelOpen(true)
            }}
         >
            Add New Vender
         </Button>

         <Modal
            title="Add New Vender"
            centered
            open={getAddNewVenderModelOpen}
            onOk={() => setAddNewVenderModelOpen(false)}
            onCancel={() => setAddNewVenderModelOpen(false)}
            width={1000}
            id="logoutModal"
            okText={"Yes"}
            cancelText={"No"}
            footer={null}

         >
            <Divider />

            <Form
               layout="vertical"
               form={addNewVenderForm}
               name="control-hooks"
               onFinish={onFinishAddNewVender}
            >

               <Form.Item label="Vender Name" name="name" rules={[
                  { required: true, message: 'Please input Vender Name!' },
               ]}
               >
                  <Input />
               </Form.Item>

               <Form.Item label="Email" name="email" rules={[
                  { required: true, type: 'email', message: 'Please input valid E-mail!!' },
               ]}
               >
                  <Input />
               </Form.Item>

               <Form.Item label="Contact No" name="contact_no" rules={[
                  { required: true, message: 'Please input Contact No!' },
               ]}
               >
                  <Input />
               </Form.Item>


               <Form.Item
                  name="plan_id"
                  label="Select Plan"
                  rules={[{ required: true, message: 'Please Select A Plan!' }]}
               >
                  <Select allowClear
                     placeholder="Select Plan"
                     options={options}
                  />
               </Form.Item>

               <Form.Item
                  name="plan_valid_up_to" label="Plan Valid Up To"
                  rules={[{ required: true, message: 'Please select Valid Date!' }]}
               >
                  <DatePicker disabledDate={disabledDate} style={{ width: '100%' }} />
               </Form.Item>


               <Form.Item >
                  <Space>
                     <Button type="primary" htmlType="submit">
                        Add Now
                     </Button>
                     <Button htmlType="button" onClick={onResetAddNewVenderFrom}>
                        Reset
                     </Button>
                  </Space>
               </Form.Item>

            </Form>

         </Modal>
      </>
   );
}
export default AddNewVender;
