
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Card, Empty, Segmented, Space } from 'antd';
import { useCreditUsageMutation } from '../../../../redux/services/venderApi';

// Register the plugin
Chart.register(ChartDataLabels);

const defultVenderHitCountFilter = 'hours';

const segmentOptions = [{
   label: 'Hours',
   value: 'hours',
}, {
   label: 'Day',
   value: 'day',
}, {
   label: 'Week',
   value: 'week',
}, {
   label: 'Month',
   value: 'month',
// }, {
//    label: 'Year',
//    value: 'year',
}];


const graphDesign = {
   barThickness: 16
}

const VenderCreditUsageChart = ({
   vender_id
}) => {

   const [getVenderHitCountFilter, setVenderHitCountFilter] = useState();
   const [fetchVenderHitCount, { data: GetHtCounts }] = useCreditUsageMutation();

   const venderList = GetHtCounts?.data?.graphData;

   const [graphData, setGraphData] = useState({
      label: [],
      value: [],
   });

   useEffect(() => {
      const labels = [];
      const hitCounts = [];
      const creditCounts = [];

      venderList?.forEach((item) => {
         labels.push(item.label);
         hitCounts.push(item?.hit_count || 0);
         creditCounts.push(item?.credit_count || 0);
      });
      setGraphData({ label: labels, value: {
         hitCounts: hitCounts,
         creditCounts: creditCounts
      } });

   }, [venderList]);

   useEffect(() => {
      setVenderHitCountFilter(defultVenderHitCountFilter);

      fetchVenderHitCount({
         id: vender_id,
         filterType: defultVenderHitCountFilter
      });
   }, [defultVenderHitCountFilter]);

   const graphFianlData = {
      labels: graphData.label,
      datasets: [
         {
            label: 'Hit Counts', // This label will be hidden
            data: graphData.value.hitCounts,
            // backgroundColor: '#999999',
            backgroundColor: 'rgb(24, 144, 255, 0.8)',
            barThickness: graphDesign?.barThickness, // Adjust the thickness of the bars
            maxBarThickness: 50, // Maximum thickness of the bars
            barPercentage: 0.8, // Adjust the width of the bars
            categoryPercentage: 1, // Adjust the spacing between bars
         },
         {
            label: 'Credit Counts', // This label will be hidden
            data: graphData.value.creditCounts,
            backgroundColor: 'rgb(108, 122, 137, 0.5)',
            barThickness: graphDesign?.barThickness, // Adjust the thickness of the bars
            maxBarThickness: 50, // Maximum thickness of the bars
            barPercentage: 0.8, // Adjust the width of the bars
            categoryPercentage: 1, // Adjust the spacing between bars
         },
      ],
   };

   const graphOptions = {
      responsive: true,
      maintainAspectRatio: false,
      // indexAxis: 'y', // This option makes the bar chart horizontal
      indexAxis: 'x', // This option makes the bar chart horizontal
      scales: {
         x: {
            beginAtZero: true,
         },
         y: {
            // display: false, // Hide the y-axis labels
         },
      },
      plugins: {
         legend: {
            display: true, // Hide the dataset label
         },
         datalabels: {
            anchor: 'end',
            align: 'end', /// start, end
            color: 'red',
            display: false,
            // formatter: (value) => value.toLocaleString(),
            formatter: (value, context) => {
               // const vender = graphData.label[context.dataIndex];
               return `${value.toLocaleString()}`; /// ${vender} (${value.toLocaleString()})
            },
         },
      },
   };

   const changeVenderHitCountFilter = (newValue) => {
      setVenderHitCountFilter(newValue);

      fetchVenderHitCount({
         id: vender_id,
         filterType: newValue
      });
   }

   return (
      <>
         <Card
            style={{ height: '480px' }}
            title="Recent Credit Uses"
            extra={
               <Space>
                  <Segmented
                     className='venderHitCountFilter'
                     name="venderHitCountFilter"
                     options={segmentOptions}
                     onChange={(value) => {
                        changeVenderHitCountFilter(value); // string
                     }}
                     defaultValue={defultVenderHitCountFilter}
                  />
               </Space>
            }
         >

            {
               venderList?.length > 0 ? (
                  <div
                     style={{ height: '350px' }}
                  >
                     <Bar data={graphFianlData} options={graphOptions} />
                  </div>
               ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
               )
            }
         </Card>
      </>
   );
};
export default VenderCreditUsageChart;
