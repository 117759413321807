import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../common/constant/constant";

import { logout } from "../slice/user.slice";
import { startLoading, stopLoading } from "../slice/commonLoading.slice";
import { setToast } from "../slice/toast.slice";

export const fiatApi = createApi({
  reducerPath: "fiatApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.ADMIN_API_URL,
    prepareHeaders: (headers, { getState, endpoints }) => {
      const { token } = getState()?.userState?.user;
      headers.set("Authorization", `${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    
    fiatList: builder.mutation({
      query: (payload) => ({
        method: "GET",
        url: `/fiat/list?page=${payload?.page}&limit=${payload?.limit}&search=${payload?.search}&status=${payload?.status}`,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(startLoading(true));
          let { data } = await queryFulfilled;
          data?.status && dispatch(stopLoading(false));
        } catch (error) {
          dispatch(stopLoading(false));
          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
    }),

    upadteFiatStatus: builder.mutation({
      query: (payload) => ({
        method: "post",
        url: `fiat/updateStatus`,
        body: payload,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          let { data } = await queryFulfilled;
          dispatch(
            setToast({
              message: data?.message,
              success: true,
              error: false,
            })
          );
        } catch (error) {
          dispatch(
            setToast({
              message:
                error?.error?.data?.data[0]?.id?.isNotEmpty ||
                error.error?.data?.message ||
                "server error",
              success: false,
              error: true,
            })
          );

          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
    }),

    syncFiatList: builder.mutation({
      query: (payload) => ({
        method: "get",
        url: `/cmc/syncFiat`,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(startLoading(true));
          let { data } = await queryFulfilled;

          dispatch(
            setToast({
              message: data?.message,
              success: true,
              error: false,
            })
          );
          dispatch(stopLoading(false));
        } catch (error) {
          dispatch(stopLoading(false));
          dispatch(
            setToast({
              message: error.error?.data?.message || "server error",
              success: false,
              error: true,
            })
          );

          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
    }),

    
  }),
});

export const {
  useFiatListMutation,
  useUpadteFiatStatusMutation,
  // useSyncCmcFiatMutation,
  // useSyncFiatInfoMutation,
  useSyncFiatListMutation,
} = fiatApi;
