import React, { useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { Layout, theme } from "antd";

import logo from "../../images/coinzone_logo.svg";

const { Header, Sider, Content } = Layout;

const PublicLayout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => { }, []);

  return (
    <>
      <Layout className="layout publicLayout" style={{ background: colorBgContainer }}>
        <Header className="displayNone"
        // displayFlex
        // style={{
        //   background: colorBgContainer,
        //   borderBottom: "solid 1px",
        //   boxShadow: "rgb(153, 153, 153, 0.5) 0px 0px 40px 10px",
        // }}
        >
          <div className="demo-logo displayFlex">
            <Link to={`/`}>
              <span className="logoSpanBx displayFlex flexWrap alignItemsCenter">
                <img className="logoImg" src={logo} />
              </span>
            </Link>
          </div>
        </Header>
      </Layout>
      <Layout>
        <Content
          className="site-layout-content"
          style={{
            padding: "30px 50px 0px 50px",
            background: colorBgContainer,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </>
  );
};
export default PublicLayout;
