import { Card, Col, Row } from "antd";
import moment from "moment";

import ActionButtonsVenders from "./actionBtns.comp";
import SettingsBtn from "./settingsBtn.comp";

const ExtraInfo = ({
   vender,
   fetchVenderList,
   selectedValue,
   currentPage,
   pageSize
}) => {
   return (
      <Row gutter={[10, 10]} style={{ paddingBottom: '25px' }}>
         <Col md={24}>
            <Card title="Authorization key" bordered={true} size="small">
               <div
                  style={{ display: "flex", justifyContent: "space-between" }}
               >
                  <Col col={3}>
                     <b>Key : </b> {vender?.security_key}
                  </Col>
                  <Col col={3}>
                     <b>Next Renew Date : </b> {moment.utc(vender?.api_hits_plan?.renew_date).format("DD/MM/YYYY HH:mm:ss A")}
                  </Col>

                  <Col col={3}>
                     {
                        vender?.status != "pending" && (
                           <ActionButtonsVenders
                              vender={vender}
                              fetchVenderList={fetchVenderList}
                              selectedValue={selectedValue}
                              currentPage={currentPage}
                              pageSize={pageSize}
                           />
                        )
                     }
                  </Col>
               </div>
            </Card>
         </Col>


         <Col md={24}>
            <Card title="Settings" bordered={true} size="small">
               <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <SettingsBtn
                     vender_id={vender?.id}
                     btn_key={'allow_add_custom_token'}
                     btn_value={vender?.settings?.allow_add_custom_token}
                  />

                  {/* <Col col={24}>
                      <b>Add Custom Token : </b> {vender?.settings?.allow_add_custom_token}
                      <Switch
                         name="allow_add_custom_token"
                         size="large"
                         checkedChildren="Allowed"
                         unCheckedChildren="Denied"
                         checked={vender?.settings?.allow_add_custom_token == '1' ? true : false}
                         // danger={cmcStatus != 'active' ? true : false}
                         onClick={(e) => {
                            // handleUpdateCmcStatus(cmcStatus);
                         }}
                      />
                   </Col> */}

               </div>
            </Card>
         </Col>
      </Row>
   );
};

export default ExtraInfo;