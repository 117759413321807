import { Card,  Statistic } from "antd";
import { Link } from "react-router-dom";

const CountersDashboard = ({
   counter
}) => {
   const statsData = [
      {
         title: 'Active Venders',
         value: counter?.activeVender || 0,
         color: '#3f8600',
         link: '/venders-list?status=active',
      },
      {
         title: 'Pending Vender Requests',
         value: counter?.venderPandingRequest || 0,
         color: '#faad14',
         link: '/venders-list?status=pending',
      },
      {
         title: 'Active Coins',
         value: counter?.activeCoins || 0,
         color: '#1890ff',
         link: '/coin-list?status=active',
      },
      {
         title: 'Active Fiats',
         value: counter?.activeFiats || 0,
         color: '#722ed1',
         link: '/fiats-list?status=active',
      },
      {
         title: 'Active Coin Conversions',
         value: counter?.activeCoinsCnversition || 0,
         color: '#f5222d',
         link: '/conversions-list?status=active',
      }
   ];

   return (
      <div className="dashCard">
         {statsData.map((stat, index) => (
            <div  key={index}>
               <Card
                  hoverable
                  style={{ cursor: 'pointer' }}
                  bordered={false}
                  className="statisticCard"
               >
                  <Link to={stat.link}>
                     <Statistic
                        className="statistic"
                        title={stat.title}
                        value={stat.value}
                        valueStyle={{
                           color: stat.color,
                        }}
                     />
                  </Link>
               </Card>
            </div>
         ))}
      </div>
   );
}
export default CountersDashboard;