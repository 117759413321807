import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  plansData: [],
};

export const plansListSlice = createSlice({
  name: "plans-info",
  initialState,
  reducers: {
    setPlansListData: (state, action) => {
      state.plansData = action.payload.data?.data;
    },
  },
});

export const { setPlansListData } = plansListSlice.actions;
export default plansListSlice.reducer;
