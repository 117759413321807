import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

export const commonLoadingSlice = createSlice({
  name: "common-loading",
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.loading = action.payload;
    },
    stopLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { startLoading, stopLoading } = commonLoadingSlice.actions;
export default commonLoadingSlice.reducer;
