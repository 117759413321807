import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { persistStore, persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

import storage from "redux-persist/lib/storage";

import userReducer from "./slice/user.slice";
import toastReducer from "./slice/toast.slice";
import activeIndexSlice from "./slice/activeIndex";
import { loginApi } from "./services/loginApi";
import { dashboardApi } from "./services/dashboardApi";
import { projectApi } from "./services/projectApi";
import { settingsApi } from "./services/settingsApi";
import { venderApi } from "./services/venderApi";
import { coinsApi } from "./services/coinsApi";

import commonLoadingSlice from "./slice/commonLoading.slice";
import { addCmcKeyApi } from "./services/cmcApi";
import addcmcSlice from "./slice/cms.slice";
import { plansListApi } from "./services/plansListApi";
import { addTokenAPi } from "./services/addTokenApi";
import plansListSlice from "./slice/plansList.slice";
import addTokenSlice from "./slice/addToken.slice";
import { alertsApi } from "./services/alertsApi";
import { fiatApi } from "./services/fiatApi";

const persistConfig = {
  key: "root",
  storage: storage,
};

const combinedReducer = combineReducers({
  [loginApi.reducerPath]: loginApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [projectApi.reducerPath]: projectApi.reducer,
  [coinsApi.reducerPath]: coinsApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [venderApi.reducerPath]: venderApi.reducer,
  
  [addCmcKeyApi.reducerPath]: addCmcKeyApi.reducer,
  [addTokenAPi.reducerPath]: addTokenAPi.reducer,
  [plansListApi.reducerPath]: plansListApi.reducer,
  [alertsApi.reducerPath]: alertsApi.reducer,
  [fiatApi.reducerPath]: fiatApi.reducer,
  
  toastState: toastReducer,
  userState: userReducer,
  activeIndex: activeIndexSlice,
  loading: commonLoadingSlice,
  addcmcSlice: addcmcSlice,
  plansList: plansListSlice,
  addTokenSlice: addTokenSlice,
});

export const rootReducers = (state, action) => {
  if (action.type === "userSlice/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducers);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // {ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],},
    }).concat(
      loginApi.middleware,
      dashboardApi.middleware,
      projectApi.middleware,
      coinsApi.middleware,
      settingsApi.middleware,
      venderApi.middleware,
      
      addCmcKeyApi.middleware,
      addTokenAPi.middleware,
      plansListApi.middleware,
      alertsApi.middleware,
      fiatApi.middleware
    ),
});

setupListeners(store.dispatch);
let persistor = persistStore(store);
export { store, persistor };
