import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import MainLayout from "../pages/authorized/layout.page";

export function PrivateRoutes() {
  const user = useSelector((state) => state.userState?.user);
  return user?.token !== undefined ? (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ) : (
    <Navigate to="/" />
  );
}
