import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as device from "react-device-detect";
import "../../../src/style/index.scss";
import { v4 as uuid } from "uuid";

import { Button, Col, Form, Input, Row } from "antd";
import {
  LeftCircleOutlined,
  LockOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import { useForgotPassMutation } from "../../redux/services/loginApi";

const ForgotPassPage = () => {
  const [forgotPass, {}] = useForgotPassMutation();

  const [deviceInfo, setDeviceInfo] = useState(null);
  const navigate = useNavigate();
  const getDeviceId = async () => {
    const uniqeId = localStorage.getItem("device_id");
    if (!uniqeId) {
      localStorage.setItem("device_id", uuid());
    }

    setDeviceInfo({
      browserName: device.browserName,
      browserVersion: device.browserVersion,
      osName: device.osName,
      osVersion: device.osVersion,
      device_id: uniqeId,
      device_type: device.isDesktop ? "desktop" : "mobile",
    });
  };

  useEffect(() => {
    getDeviceId();
  }, []);

  const ForgotPassSubmit = async (ForgotPassValue) => {
    ForgotPassValue = {
      ...ForgotPassValue,
      device_info: deviceInfo,
    };
    forgotPass(ForgotPassValue).then((resp) => {
      resp?.data?.status && navigate("/");
    });
  };

  return (
    <>
      <Row style={{ marginTop: "10vh", minHeight: "65vh" }}>
        <Col
          xs={{ span: 18, offset: 3 }}
          sm={{ span: 18, offset: 3 }}
          md={{ span: 20, offset: 2 }}
          lg={{ span: 8, offset: 8 }}
          xl={{ span: 8, offset: 8 }}
          xxl={{ span: 8, offset: 8 }}
        >
          <Row
            className="loginInnerSec"
            justify="space-around"
            align="middle"
            style={{
              minHeight: "100%",
              boxShadow: "rgb(153, 153, 153, 0.5) 0px 0px 40px 10px",
              borderRadius: "22px",
            }}
          >
            <Col
              xs={{ span: 20, offset: 0 }}
              sm={{ span: 20, offset: 0 }}
              md={{ span: 20, offset: 0 }}
              lg={{ span: 20, offset: 0 }}
              xl={{ span: 20, offset: 0 }}
              xxl={{ span: 20, offset: 0 }}
            >
              <Form
                name="normal_ForgotPass"
                className="ForgotPass-form"
                initialValues={{ remember: true }}
                onFinish={ForgotPassSubmit}
              >
                <Form.Item
                  style={{
                    marginBottom: "40px",
                  }}
                >
                  <h1>
                    {" "}
                    <WarningOutlined /> Forgot Password{" "}
                  </h1>
                  <span>
                    {" "}
                    Enter email and we'll send you a link to reset your
                    password.{" "}
                  </span>
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input
                    size="large"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                  />
                </Form.Item>

                <Form.Item>
                  <Link className="login-form-forgot" to="/">
                    <LeftCircleOutlined /> Back to login
                  </Link>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="ForgotPass-form-button"
                  >
                    Reset my Password
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default ForgotPassPage;
