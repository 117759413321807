import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../common/constant/constant";
import { logout } from "../slice/user.slice";
import { setToast } from "../slice/toast.slice";
import { setTokenData } from "../slice/addToken.slice";

export const addTokenAPi = createApi({
  reducerPath: "add-token-service",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.ADMIN_API_URL,
    prepareHeaders: (headers, { getState, endpoints }) => {
      const { token } = getState()?.userState?.user;
      headers.set("Authorization", `${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // addToken: builder.mutation({
    //   query: (payload) => ({
    //     url: "coins/addToken",
    //     method: "POST",
    //     body: payload,
    //   }),
    //   transformResponse: (result) => result,
    //   async onQueryStarted(args, { dispatch, queryFulfilled }) {
    //     try {
    //       const { data } = await queryFulfilled;

    //       if (data?.status) {
    //         dispatch(
    //           setToast({
    //             message: data.message,
    //             success: true,
    //             error: false,
    //           })
    //         );
    //       }
    //     } catch (error) {
    //       dispatch(
    //         setToast({
    //           message: error?.error?.data?.message || "server error",
    //           success: false,
    //           error: true,
    //         })
    //       );
    //       if (error?.error?.status == 401) {
    //         setTimeout(() => {
    //           dispatch(logout());
    //         }, 2000);
    //       }
    //     }
    //   },

    //   async onCacheEntryAdded(arg, { dispatch }) {},
    // }),

    filterOutToken: builder.mutation({
      query: (payload) => ({
        url: "coins/searchToken",
        method: "POST",
        body: payload,
      }),
      transformResponse: (result) => result,

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setTokenData(data.data));
          if (data?.status) {
            dispatch(
              setToast({
                message: data.message,
                success: true,
                error: false,
              })
            );
          }
        } catch (error) {
          dispatch(
            setToast({
              message: error?.error?.data?.message || "server error",
              success: false,
              error: true,
            })
          );
          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },

      async onCacheEntryAdded(arg, { dispatch }) { },
    }),
  }),
});

export const {
  // useAddTokenMutation, 
  useFilterOutTokenMutation
} = addTokenAPi;
