import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";

const { Option } = Select

const KeyFiltersComop = ({
   reseteKeySearchKeywordFn,
   keySearchKeywordFn
}) => {

   const formRef = React.useRef(null);

   const onFinish = (values) => {
      (values?.search || values?.status || values?.orderColumn || values?.sortBy || values?.plan_type) && keySearchKeywordFn(
         values?.search || "",
         values?.status || "",
         values?.orderColumn || "",
         values?.sortBy || "",
         values?.plan_type || ""
      );
   };

   const onReset = () => {
      formRef.current?.resetFields();
      reseteKeySearchKeywordFn();
   };

   const srchFromInitialValues = {
      orderColumn: 'last_expired_at',
      sortBy: 'asc'
   };


   return (
      <div className="keyListPage">
         <Form
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            // onChange={(e) => setKeyValue(e.target.value)}
            initialValues={srchFromInitialValues}
         >
            <Row gutter={20} className="p2">
               <Col xs={24} sm={12} md={6} lg={6}>
                  <Form.Item name="search">
                     <Input placeholder="Search key" allowClear />
                  </Form.Item>
               </Col>

               <Col xs={24} sm={9} md={4} lg={3} >
                  <Form.Item name="status" >
                     <Select placeholder="Search Status" allowClear >
                        <Option value="active"> Active </Option>
                        <Option value="inactive">Inactive</Option>
                     </Select>
                  </Form.Item>
               </Col>

               <Col xs={24} sm={9} md={4} lg={3} >
                  <Form.Item name="plan_type" >
                     <Select placeholder="Plan Type" allowClear>
                        <Option value="free"> Free </Option>
                        <Option value="paid"> Paid </Option>
                     </Select>
                  </Form.Item>
               </Col>


               <Col xs={24} sm={9} md={4} lg={3} >
                  <Form.Item name="orderColumn" >
                     <Select placeholder="Plan Type">
                     <Option value="last_expired_at"> Last Expired At </Option>
                        <Option value="status"> Status </Option>
                     </Select>
                  </Form.Item>
               </Col>

               <Col xs={24} sm={9} md={4} lg={3}>
                  <Form.Item name="sortBy" >
                     <Select placeholder="Sort">
                        <Option value="asc"> ASC </Option>
                        <Option value="desc">DESC</Option>
                     </Select>
                  </Form.Item>
               </Col>

               <Col xs={24} sm={12} md={6} lg={6}>
                  <Form.Item className="searchresetbtn">
                     <Button type="primary" htmlType="submit">
                        Search
                     </Button>
                     <Button htmlType="button" onClick={onReset} type="primary" danger>
                        Reset
                     </Button>
                  </Form.Item>
               </Col>
            </Row>
         </Form>
      </div>
   );
};
export default KeyFiltersComop;
