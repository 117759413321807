export const Ucfirst = (str) => {
   if (str) {
      const firstLetter = str.slice(0, 1);
      return firstLetter.toUpperCase() + str.substring(1);
   }
   return null;
};

export const convertAbbreviateNumber = (number) => {
   const suffixes = ["", "k", "M", "B", "T"];
   let suffixIndex = 0;
   while (number >= 1000 && suffixIndex < suffixes.length - 1) {
      number /= 1000;
      suffixIndex++;
   }
   return number + suffixes[suffixIndex];
}


export const statusColorCode = (targetDate) => {
   const today = new Date();
   const target = new Date(targetDate);
   // const diffTime = Math.abs(target - today);
   // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
   
   const diffTime = target - today; // Calculate the difference in time (can be negative)
   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Difference in days

   // if (diffDays >= 7) return "#4CAF50"; // Green (Safe)
   // switch (diffDays) {
   //    case 6:
   //       return "#8BC34A"; // Light Green
   //    case 5:
   //       return "#CDDC39"; // Lime Green
   //    case 4:
   //       return "#FFEB3B"; // Bright Yellow
   //    case 3:
   //       return "#FFC107"; // Amber
   //    case 2:
   //       return "#FF9800"; // Deep Orange
   //    case 1:
   //       return "#F44336"; // Red
   //    default:
   //       return "#D32F2F"; // Dark Red (Danger)
   // }

   if (diffDays >= 7) return {
      bgcolor: "rgba(76, 175, 80, 0.2)"
   }; // Green (Safe)

   switch (diffDays) {
      case 6:
         return {
            bgcolor: "rgba(139, 195, 74, 0.2)"
         }; // Light Green
      case 5:
         return {
            bgcolor: "rgba(205, 220, 57, 0.2)"
         }; // Yellow-Green
      case 4:
         return {
            bgcolor: "rgba(255, 235, 59, 0.2)"
         }; // Yellow
      case 3:
         return {
            bgcolor: "rgba(255, 193, 7, 0.2)"
         }; // Orange
      case 2:
         return {
            bgcolor: "rgba(255, 152, 0, 0.2)"
         }; // Orange-Red
      case 1:
         return {
            bgcolor: "rgba(244, 67, 54, 0.2)"
         }; // Red (Darker Red)
      default:
         return {
            bgcolor: "rgba(211, 47, 47, 0.2)"
         };

         // ; // Dark Red (Danger)
   }

}