import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Avatar, Layout, Menu, Modal, theme } from "antd";
import logo from "../../images/coinzone_logo.svg";

import iconCoin from "../../images/coins.png";
import iconFiat from "../../images/fiatLogo.png";

import priceolan from "../../images/priceplan.png";
import {
   AlertOutlined,
   DashboardOutlined,
   KeyOutlined,
   LogoutOutlined,
   SecurityScanOutlined,
   SettingOutlined,
   UsergroupAddOutlined,
} from "@ant-design/icons";

import { logout } from "../../redux/slice/user.slice";
import { setToast } from "../../redux/slice/toast.slice";

const { Header, Sider, Content } = Layout;

const navbarArr = [
   { pathname: "/dashboard", value: "1" },
   { pathname: "/venders-list", value: "2" },
   { pathname: "/coin-list", value: "3" },
   { pathname: "/settings", value: "4" },
   { pathname: "/price-source/cmc/keylist", value: "5" },
   { pathname: "/planslist", value: "6" },
   { pathname: "/alerts", value: "7" },
];

const adminToken = localStorage.getItem("adminToken") ? localStorage.getItem("adminToken") : null;
const adminName = localStorage.getItem("adminName") ? localStorage.getItem("adminName") : null;
const adminEmail = localStorage.getItem("adminEmail") ? localStorage.getItem("adminEmail") : null;

const MainLayout = () => {
   const dispatch = useDispatch();
   const location = useLocation();
   const [collapsed, setCollapsed] = useState(true);
   const [logoutModalOpen, setLogoutModalOpen] = useState(false);

   const [activeIndex, setActiveIndex] = useState("");
   const userName = localStorage.getItem("userName");

   const {
      token: { colorBgContainer },
   } = theme.useToken();

   const onLogout = () => {
      dispatch(logout(null));
      dispatch(
         setToast({
            message: "Logout successfully",
            success: true,
            error: false,
         })
      );
   };

   let menuItems = [{
      label: <Link to="/dashboard"> Dashboard </Link>,
      key: "dashboard",
      icon: <DashboardOutlined />
   }, {
      label: <Link to="/venders-list"> Venders List </Link>,
      key: "vendersList",
      icon: <UsergroupAddOutlined />
   }, {
      label: <Link to="/coin-list"> Coins List </Link>,
      key: "coinsList",
      icon: <img src={iconCoin} width={20} />,
   }, {
      label: <Link to="/fiat-list"> Fiat List </Link>,
      key: "fiatList",
      icon: <img src={iconFiat} width={20} />
   }, {
      key: "cmckeylist",
      label: <Link to="/price-source/cmc/keylist"> Cmc Key List </Link>,
      icon: <KeyOutlined />
   }, {
      key: "planslist",
      label: <Link to="/planslist"> Plans list </Link>,
      icon: <img src={priceolan} width={16} height={16} />
   }, {
      key: "alerts",
      label: <Link to="/alerts"> Alerts </Link>,
      icon: <AlertOutlined width={16} height={16} />
   }, {
      key: "settings",
      label: <Link to="#"> Settings </Link>,
      icon: <SettingOutlined />,
      children: [{
         label: <Link to="/settings/security"> Security </Link>,
         key: "settingsSecurity",
         icon: <SecurityScanOutlined />
      }, {
         label: <Link to="/settings/users-alert"> Alert notification </Link>,
         key: "settingsUsersAlert",
         icon: <AlertOutlined />
      }]
   }, {
      key: "logout",
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: () => {
         setLogoutModalOpen(true);
      },
   }];

   useEffect(() => {
      navbarArr.map((item) => {
         if (item.pathname == location.pathname) {
            setActiveIndex(item.value);
         }
      });
   }, [location.pathname]);

   return (
      <>
         <Layout className="layout adminMainLayout" style={{ background: colorBgContainer }}>
            <Header className="dashboard-header">
               <div className="logo displayFlex">
                  <Link to={`/dashboard`}>
                     <img alt="" src={logo}></img>
                  </Link>
               </div>

               <div className="headerNameRgtCol" role="button">
                  <span className="headerNameSec" style={{ marginRight: "8px" }}>
                     <span style={{ fontWeight: "700" }}> Welcome: </span>
                     <span className="headerAdminName" style={{ fontWeight: "500" }}> {adminEmail} </span>
                  </span>
                  <Avatar style={{ backgroundColor: '#f56a00' }}>{adminEmail?.charAt(0)?.toUpperCase()}</Avatar>
               </div>
            </Header>
         </Layout >

         <Layout className="main-layout" style={{
            // minHeight: "90vh"
         }} >
            <Sider
               className="dashboard-sidebar"
               style={{ backgroundColor: "white", color: "black" }}
               collapsible
               collapsed={collapsed}
               onCollapse={(value) => setCollapsed(value)}
            >
               <div className="demo-logo-vertical" />
               <Menu
                  theme="light"
                  selectedKeys={[activeIndex]}
                  mode="inline"
                  items={menuItems}
               />
            </Sider>
            <Content
               className="main content-section"
            >
               <Layout
                  className="site-layout-content"
                  style={{ backgroundColor: "#f0f2f5" }}
               >
                  <Outlet />
               </Layout>
            </Content>
         </Layout>

         <Modal
            id="logoutModal"
            title="Do you want to Logout?"
            centered
            open={logoutModalOpen}
            onOk={() => onLogout(false)}
            onCancel={() => setLogoutModalOpen(false)}
            width={500}
            okText={"Yes"}
            cancelText={"No"}
         ></Modal>
      </>
   );
};
export default MainLayout;
