import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import PublicLayout from "../pages/public/layout.page";

export function RestrictedRoutes() {
  const user = useSelector((state) => state.userState?.user);

  return user?.token == undefined ? (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  ) : (
    <Navigate to="/dashboard" />
  );
}
