import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../common/constant/constant";
import { logout } from "../slice/user.slice";

export const alertsApi = createApi({
   reducerPath: "alert-api",
   baseQuery: fetchBaseQuery({
      baseUrl: CONFIG.ADMIN_API_URL,
      prepareHeaders: (headers, { getState, endpoints }) => {
         const { token } = getState()?.userState?.user;
         headers.set("Authorization", `${token}`);
         return headers;
      },
   }),
   endpoints: (builder) => ({
      alertsData: builder.mutation({
         query: (payload) => ({
            url: "settings/alerts/errorList",
            method: "get",
            body: payload,
         }),
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               const { data } = await queryFulfilled;
            } catch (error) {
               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);
               }
            }
         },

         async onCacheEntryAdded(arg, { dispatch }) { },
      }),
   }),
});

export const { useAlertsDataMutation } = alertsApi;
