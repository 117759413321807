import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../common/constant/constant";
import { logout } from "../slice/user.slice";
import { setKeyDetail, setcmsList } from "../slice/cms.slice";
import { setToast } from "../slice/toast.slice";
import { startLoading, stopLoading } from "../slice/commonLoading.slice";

export const addCmcKeyApi = createApi({
   reducerPath: "cmsKey",
   baseQuery: fetchBaseQuery({
      baseUrl: CONFIG.ADMIN_API_URL,
      prepareHeaders: (headers, { getState, endpoints }) => {
         const { token } = getState()?.userState?.user;
         headers.set("Authorization", `${token}`);
         return headers;
      },
   }),
   endpoints: (builder) => ({
      geneRateCmcKey: builder.mutation({
         query: (payload) => ({
            url: "cmc/addCMCKey",
            method: "POST",
            body: payload,
         }),
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               const { data } = await queryFulfilled;
               if (data?.status) {
                  dispatch(
                     setToast({
                        message: data.message,
                        success: true,
                        error: false,
                     })
                  );
               }
            } catch (error) {
               dispatch(
                  setToast({
                     message: error?.error?.data?.message || "server error",
                     success: false,
                     error: true,
                  })
               );
               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);
               }
            }
         },

         async onCacheEntryAdded(arg, { dispatch }) { },
      }),
      
      keysList: builder.mutation({
         query: (payload) => ({
            method: "GET",
            // url: `/cmc/keys/list`,
            url: `/cmc/keylist?limit=${payload.limit}&page=${payload.page}&search=${payload?.search}&status=${payload?.status}&orderColumn=${payload?.orderColumn}&sortBy=${payload?.sortBy}&plan_type=${payload?.plan_type}`,

            // ?
         }),
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               dispatch(startLoading(true));
               let { data } = await queryFulfilled;

               dispatch(setcmsList(data?.data));

               data?.status && dispatch(stopLoading(false));
            } catch (error) {
               dispatch(stopLoading(false));
               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);
               }
            }
         },
      }),

      updateCmsKeyStatus: builder.mutation({
         query: (payload) => {
            return {
               method: "Post",
               url: `/cmc/updateKeyStatus`,
               body: payload,
            };
         },
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               let { data } = await queryFulfilled;
               dispatch(setcmsList(data?.data));
               dispatch(
                  setToast({
                     message: data?.message,
                     success: true,
                     error: false,
                  })
               );
            } catch (error) {
               dispatch(
                  setToast({
                     message:
                        error?.error?.data?.message ||
                        (error?.error?.status == "FETCH_ERROR" && "Server error "),
                     success: false,
                     error: true,
                  })
               );
               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);
               }
            }
         },
      }),

      keyDetails: builder.mutation({
         query: (payload) => {
            return {
               method: "Post",
               url: `/cmc/keyInfo`,
               body: payload,
            };
         },
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               let { data } = await queryFulfilled;
               dispatch(setKeyDetail(data?.data));
            } catch (error) {
               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);
               }
            }
         },
      }),

      updateCmsStatus: builder.mutation({
         query: (payload) => {
            return {
               method: "Post",
               url: `/cmc/updateStatus`,
               body: payload,
            };
         },
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               let { data } = await queryFulfilled;
               dispatch(setcmsList(data?.data));
               dispatch(
                  setToast({
                     message: data?.message,
                     success: true,
                     error: false,
                  })
               );
            } catch (error) {
               dispatch(
                  setToast({
                     message:
                        error?.error?.data?.message ||
                        (error?.error?.status == "FETCH_ERROR" && "Server error "),
                     success: false,
                     error: true,
                  })
               );
               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);
               }
            }
         },
      }),


   }),
});

export const {
   useGeneRateCmcKeyMutation,
   useKeysListMutation,
   useUpdateCmsKeyStatusMutation,
   useKeyDetailsMutation,
   useUpdateCmsStatusMutation
} = addCmcKeyApi;
