import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { CONFIG } from "../../common/constant/constant";
import { toast } from "react-toastify";

import { logout } from "../../redux/slice/user.slice";

export const dashboardApi = createApi({
   reducerPath: "dashboardApi",
   baseQuery: fetchBaseQuery({
      baseUrl: CONFIG.ADMIN_API_URL,
      prepareHeaders: (headers, { getState, endpoints }) => {
         const { token } = getState()?.userState?.user;
         headers.set("Authorization", `${token}`);
         return headers;
      },
   }),
   endpoints: (builder) => ({
      dashboardData: builder.mutation({
         query: (payload) => ({
            method: "GET",
            url: `dashboard`,
         }),
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               let { data } = await queryFulfilled;
            } catch (error) {
               toast.error(error?.error?.data?.message, { autoClose: 2000 });

               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);
               }
            }
         },
      }),

      venderHitCountData: builder.mutation({
         query: (payload) => ({
            method: "GET",
            url: `venderHitCounts?filterType=${payload?.filterType}`,
         }),
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               let { data } = await queryFulfilled;
            } catch (error) {
               toast.error(error?.error?.data?.message, { autoClose: 2000 });

               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);
               }
            }
         },
      }),

      SubsExpData: builder.mutation({
         query: (payload) => ({
            method: "GET",
            url: `subscriptionExpiring`,
         }),
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               let { data } = await queryFulfilled;
            } catch (error) {
               toast.error(error?.error?.data?.message, { autoClose: 2000 });

               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);
               }
            }
         },
      })


   }),
});

export const {
   useDashboardDataMutation,
   useVenderHitCountDataMutation,
   useSubsExpDataMutation
} = dashboardApi;
