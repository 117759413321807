import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cmsList: [],
  keyDetails: [],
};

export const addcmcSlice = createSlice({
  name: "admin-cms",
  initialState,
  reducers: {
    setcmsList: (state, action) => {
      state.cmsList = action.payload;
    },
    setKeyDetail: (state, action) => {
    
      state.keyDetails = action.payload;
    },
  },
});

export const { setcmsList, setKeyDetail } = addcmcSlice.actions;
export default addcmcSlice.reducer;
