const REACT_APP_API_URL = process.env.REACT_APP_BASE_URL;
const URL_KEY_STRING = process.env.REACT_APP_URL_KEY_STRING;

const CONFIG = {
  API_URL: REACT_APP_API_URL,
  ADMIN_API_URL: `${REACT_APP_API_URL}admin/`,
  URL_KEY_STRING: URL_KEY_STRING  
};

const headers = (headers, { getState, endpoints }) => {
  const { token } = getState()?.userState?.user;
  headers.set("Authorization", `${token}`);
  return headers;
};
































































































export { CONFIG, headers };
