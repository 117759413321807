import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as device from "react-device-detect";
import { v4 as uuid } from "uuid";
import logo from "../../images/coinzone_logo.svg";
import { Button, Col, Form, Input, Row } from "antd";
import {
  LockOutlined,
  RightCircleTwoTone,
  UserOutlined,
} from "@ant-design/icons";

import { useLoginAuthMutation } from "../../redux/services/loginApi";

const DemoBox = (props) => (
  <p className={`height-${props.value}`}>{props.children}</p>
);

const LoginPage = () => {
  const [loginAuth, { }] = useLoginAuthMutation();

  const [deviceInfo, setDeviceInfo] = useState(null);

  const getDeviceId = async () => {
    const uniqeId = localStorage.getItem("device_id");
    if (!uniqeId) {
      localStorage.setItem("device_id", uuid());
    }

    setDeviceInfo({
      browserName: device.browserName,
      browserVersion: device.browserVersion,
      osName: device.osName,
      osVersion: device.osVersion,
      device_id: uniqeId,
      device_type: device.isDesktop ? "desktop" : "mobile",
    });
  };

  useEffect(() => {
    getDeviceId();
  }, []);

  const LoginFromSubmit = async (loginValue) => {
    loginValue = {
      ...loginValue,
      device_info: deviceInfo,
    };

    loginAuth(loginValue);
  };

  return (
    <>
      <Row style={{ marginTop: "10vh", minHeight: "65vh" }}>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 22, offset: 1 }}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          <Row
            className="loginInnerSec loginpage"
            justify="space-around"
            align="middle"

          >
            <Col className="logoCol alignItemsCenter displayFlex flexDirectionColumn"
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 9, offset: 0 }}
              xl={{ span: 9, offset: 0 }}
              xxl={{ span: 9, offset: 0 }}
              style={{
                minHeight: "100%",
              }}
            >
              <img style={{ height: "80px", width: "200px" }} src={logo}></img>
              <h1 style={{ padding: "10px", textAlign: "center" }}>
                Welcome To <span style={{ color: "#3498db" }}>CoinZone Admin</span>{" "}
              </h1>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 9, offset: 0 }}
              lg={{ span: 9, offset: 0 }}
              xl={{ span: 9, offset: 0 }}
              xxl={{ span: 9, offset: 0 }}
            >
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={LoginFromSubmit}
                style={{ padding: "10px" }}
              >
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input
                    size="large"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input.Password
                    size="large"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="remember"
                        valuePropName="checked"
                        noStyle
                      ></Form.Item>{" "}
                      <br />
                    </Col>

                    {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item>
                        <Link
                          className="login-form-forgot forgotpassword"
                          to="/forgot-password"
                        >
                          Forgot password <RightCircleTwoTone />
                        </Link>
                      </Form.Item>
                    </Col> */}
                  </Row>
                </Form.Item>
                <Form.Item className="loginbtn">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default LoginPage;
