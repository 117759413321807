import { Button, Card, Form, Input, InputNumber, Modal, Pagination, Row, Space, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../../index.scss"
import {
  useAddNewPlanMutation,
  useEditPlanMutation,
  usePlansListRecordMutation,
  useUpdatePlanStatusMutation
} from "../../../redux/services/plansListApi";

import {
  CheckOutlined,
  CloseOutlined,
  DeleteFilled,
  EditFilled
} from "@ant-design/icons";
import { convertAbbreviateNumber } from "../../../Helper/utility.helper";

const PlansList = () => {
  const defaultPageSize = 10;
  const defaultCurrent = 1;

  const [addNewPlanForm] = Form.useForm();
  const [editPlanForm] = Form.useForm();

  const [addNewPlanReq] = useAddNewPlanMutation();
  const [editPlanReq] = useEditPlanMutation();

  const [updatePlanStatus] = useUpdatePlanStatusMutation();

  const [plansListRecord] = usePlansListRecordMutation();
  const [currentPage, setCurrentPage] = useState(defaultCurrent);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [newPlanModalOpen, setNewPlanModalOpen] = useState(false);
  const [editPlanModalOpen, setEditPlanModalOpen] = useState(false);

  const { count, rows } = useSelector((data) => data?.plansList?.plansData);

  const { loading } = useSelector((state) => state?.loading);

  const onPageChange = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPageSize(pageSize);
    plansListRecord({
      page: pageNumber,
      limit: pageSize,
    });
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record) => {
        return record?.description == null ? "N/A" : record?.description;
      },
    },
    {
      title: "Monthly Hits Limit",
      dataIndex: "monthly_hits",
      key: "monthly_hits",
      render: (text, record) => {
        return `${record?.monthly_hits} (${convertAbbreviateNumber(record?.monthly_hits || 0)})`;
      },
      // 
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text, record) => {
        return `$${record?.price}`;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Space>
            {
              record?.title != "basic" ?
                <>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={record?.status == "active" ? true : false}
                    onChange={(e) => {
                      HableUpdatePlanStatus(
                        record?.id,
                        record?.status == "active" ? "disabled" : "active"
                      );
                    }}
                  />

                  <DeleteFilled style={{ color: '#eb2f96' }} onClick={(e) => {

                    HableUpdatePlanStatus(
                      record?.id, "deleted"
                    );
                  }} />
                </>
                : ""
            }
            <EditFilled style={{ color: '#a0d911' }} onClick={(e) => {

              // setEditPlan({
              //   title: record?.title
              // })

              editPlanForm.setFieldsValue({
                id: record?.id,
                title: record?.title,
                currency_conversions_limit: record?.currency_conversions_limit,
                daily_hits: record?.daily_hits,
                description: record?.description,
                minute_hits: record?.minute_hits,
                monthly_hits: record?.monthly_hits,
                price: record?.price,
                weekly_hits: record?.weekly_hits
              });

              setEditPlanModalOpen(true)
            }} />
          </Space>
        );
      }
    }
  ];

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
    },
  };


  const HableUpdatePlanStatus = (id, status) => {
    updatePlanStatus({
      id: id,
      status: status, /// == "active" ? "inactive" : "active",
    }).then(
      (resp) =>
        resp?.data?.status &&
        plansListRecord({ page: defaultCurrent, limit: defaultPageSize })
    );
  }

  const SubmitNewPlanFrom = (values) => {
    addNewPlanReq(values).then((data) => {
      plansListRecord({
        page: currentPage,
        limit: pageSize,
      });
      setNewPlanModalOpen(false);
      addNewPlanForm.resetFields();
    });

    // venderStatusUpdate({
    //   status: userData?.status,
    //   id: userData?.id,
    // }).then((resp) => {
    //   resp?.data?.status && plansListRecord();
    // });

  };

  const SubmitEditPlanFrom = (values) => {
    editPlanReq(values).then((data) => {
      plansListRecord({
        page: currentPage,
        limit: pageSize,
      });
      setEditPlanModalOpen(false);
      editPlanForm.resetFields();
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  useEffect(() => {
    plansListRecord({
      page: currentPage,
      limit: pageSize,
    });
  }, []);


  return (
    <div className="PlanList">
      <Card title="Plans List" style={{ border: "none" }} extra={
        <> <Button type="primary" key="addNewPlanBtn" onClick={() => setNewPlanModalOpen(true)}> Add New Plan </Button> </>
      }
      />
      <Table pagination={false} dataSource={rows} columns={columns} loading={loading} />

      <Row className="PaginationRow justifyContentCenter">
        {count >= 10 && (<Pagination showQuickJumper current={currentPage} total={count} pageSize={pageSize} onChange={onPageChange} responsive={true} />)}
      </Row>


      {/* Modal and form for Add a new Price Plan */}
      <Modal title="Add a new Price Plan" centered
        open={newPlanModalOpen} onOk={() => setNewPlanModalOpen(false)} onCancel={() => setNewPlanModalOpen(false)}
        width={1000} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}
      >
        <Card title="" style={{ maxWidth: "100%" }}>
          <Form
            {...formItemLayout}
            variant="filled" style={{ maxWidth: 1000 }} layout="vertical"
            form={addNewPlanForm} initialValues={{ layout: "vertical" }}
            onFinish={SubmitNewPlanFrom} onFinishFailed={onFinishFailed} autoComplete="off"
          >
            <Form.Item label="Title" name="title" rules={[
              { required: true, message: 'Please input title!' },
            ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Description" name="description" >
              <Input.TextArea />
            </Form.Item>

            {/*  */}
            <Form.Item style={{ marginBottom: 0 }} >
              <Form.Item label="Hits per month" name="monthly_hits" rules={[
                { required: true },
              ]}
                style={{
                  display: 'inline-block',
                  width: 'calc(50% - 10px)',
                  // width: '100%',
                  // margin: '0 8px',
                }}
              >
                <InputNumber style={{ width: '100%' }} placeholder="Hits per month" min={0} />
              </Form.Item>

              <Form.Item label="Price" name="price" rules={[
                { required: true },
              ]}
                style={{
                  display: 'inline-block',
                  width: 'calc(50% - 8px)',
                  // width: '100%',
                  margin: '0 8px',
                }}
              >
                <InputNumber style={{ width: '100%' }} placeholder="Price" min={0} />
              </Form.Item>             
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 20, span: 4 }} >
              <Button type="primary" htmlType="submit"> Submit </Button>
            </Form.Item>
          </Form>
        </Card>
      </Modal>


      {/* Modal and form for edit Price Plan */}
      <Modal title="Edit Price Plan" key="editPricePlanModal" centered
        open={editPlanModalOpen} onOk={() => setEditPlanModalOpen(false)} onCancel={() => setEditPlanModalOpen(false)}
        width={1000} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}
      >
        <Card title="" style={{ maxWidth: "100%" }}>
          <Form
            {...formItemLayout}
            variant="filled" style={{ maxWidth: 1000 }} layout="vertical"
            form={editPlanForm} initialValues={{ layout: "vertical" }}
            onFinish={SubmitEditPlanFrom} onFinishFailed={onFinishFailed} autoComplete="off"
          >
            {/*  */}
            <Form.Item name="id" hidden={true} rules={[{ required: true }]}>
              <Input disabled />
            </Form.Item>

            <Form.Item label="Title" name="title" rules={[
              { required: true, message: 'Please input title!' },
            ]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item label="Description" name="description">
              <Input.TextArea />
            </Form.Item>

            {/*  */}
            <Form.Item style={{ marginBottom: 0 }} >
              <Form.Item label="Hits per month" name="monthly_hits" rules={[
                { required: true },
              ]}
              style={{ display: 'inline-block', width: 'calc(50% - 10px)' }}
              >
                <InputNumber style={{ width: '100%' }} placeholder="Hits per month" min={0} />
              </Form.Item>

              <Form.Item label="Price" name="price" rules={[
                { required: true },
              ]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px', }}
              >
                <InputNumber style={{ width: '100%' }} placeholder="Input Hits per week" min={0} />
              </Form.Item>
            </Form.Item>


            <Form.Item wrapperCol={{ offset: 20, span: 4 }} >
              <Button type="primary" htmlType="submit"> Submit </Button>
            </Form.Item>
          </Form>
        </Card>
      </Modal>


    </div>
  );
}
export default PlansList;
