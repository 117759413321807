import React, { useEffect, useState } from "react";

import { Breadcrumb, Card, Tree } from "antd";

import {
   useNotificationSettingsDataMutation,
   useUpdateNotificationSettingsMutation,
} from "../../../redux/services/settingsApi";

const UserNotificationAlertSettingsPage = () => {
   const [notificationSettingsData, { data: GetSettingsData }] = useNotificationSettingsDataMutation();
   const [updateNotificationSettings, { data: updatedSettings }] = useUpdateNotificationSettingsMutation();

   const [checkedKeys, setCheckedKeys] = useState();

   const SettingsData = GetSettingsData?.data;


   useEffect(()=>{
      const checkedKeysList = [];
      if (SettingsData && SettingsData?.list && Array.isArray(SettingsData?.list)) {
         for (let setting of SettingsData?.list) {   
            if (setting?.email == "1") {
               checkedKeysList.push(setting?.key);
            }
         }
      }
      setCheckedKeys(checkedKeysList);
   },[SettingsData])

   const EditSettingFromSubmit = (checkedKeys, info) => {
      const values = {
         checked: info?.checked,
         node: info?.node
      };

      updateNotificationSettings(values).then((data) => {
         if (data?.data?.status) {
            // setEditSettingModal(false);
            notificationSettingsData();
         }
      });
   };



   const treeData = [{
      title: 'Notify me by email',
      key: 'notify_me_by_email',
      children: SettingsData?.list,
   }];

   useEffect(() => {
      notificationSettingsData();
   }, []);



   return (
      <>
         <Card>
            <Breadcrumb
               items={[{
                  title: "Dashboard",
               }, {
                  title: "Settings",
               }, {
                  title: "User notification alert",
               }]}
            />
         </Card>

         {/* <Table pagination={false} dataSource={settingList} columns={columns} /> */}
         <Card title="User notification alert settings" >
            <h3> What plan usage alerts would you like to receive? </h3>
            <Tree checkable
               // onSelect={onSelect}
               onCheck={EditSettingFromSubmit}
               treeData={treeData}
               defaultExpandedKeys={['notify_me_by_email']}
               checkedKeys={checkedKeys}
            />
         </Card>

      </>
   );
};
export default UserNotificationAlertSettingsPage;
