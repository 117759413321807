import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import { Avatar, Button, Card, Collapse, Flex, List, Row, Space, Switch, Table, Tooltip, Typography } from "antd";

import { useSyncCmcCoinsMutation, useSyncCoinsHistoryMutation, useUpadteCoinStatusMutation, useUpadteSyncCryptoPairMutation } from "../../../../redux/services/coinsApi";

import { CheckOutlined, CloseOutlined, GlobalOutlined, IeOutlined, InfoCircleOutlined, MessageOutlined, NotificationOutlined, RedditOutlined, TwitterOutlined, WechatOutlined } from "@ant-design/icons";

const { Text } = Typography;


const CoinListCompo = ({
   fetchCoinsList,
   coinList,
   currentPage,
   pageSize,
   getKeyword,
   getStatus,
   orderColumn,
   sortBy
}) => {
   const { loading } = useSelector((state) => state?.loading);
   const [syncCmcCoins] = useSyncCmcCoinsMutation();
   const [upadteCoinStatus] = useUpadteCoinStatusMutation();
   const [upadteSyncCryptoPair] = useUpadteSyncCryptoPairMutation();
   const [syncCoinsHistory] = useSyncCoinsHistoryMutation();

   const syncCoinFn = (record) => {
      syncCmcCoins({ id: record?.id })?.then(
         (resp) =>
            resp &&
            fetchCoinsList({
               page: currentPage,
               limit: pageSize,
               status: getStatus,
               keyword: getKeyword,
               orderColumn: orderColumn,
               sortBy: sortBy
            })
      );
   };

   const syncHistoricalFn = (record) => {
      syncCoinsHistory({ id: record?.id })?.then(
         (resp) =>
            resp &&
            fetchCoinsList({
               page: currentPage,
               limit: pageSize,
               status: getStatus,
               keyword: getKeyword,
               orderColumn: orderColumn,
               sortBy: sortBy
            })
      );
   };

   const changeCoinStatus = (id, status) => {
      const values = {
         id: id,
         status: status,
      };
      upadteCoinStatus(values).then((data) => {
         fetchCoinsList({
            page: currentPage,
            limit: pageSize,
            status: getStatus,
            keyword: getKeyword,
            orderColumn: orderColumn,
            sortBy: sortBy
         });
      });
   };

   const syncCryptoPair = (id, status) => {
      const values = {
         id: id,
         status: status,
      };

      upadteSyncCryptoPair(values).then((data) => {
         fetchCoinsList({
            page: currentPage,
            limit: pageSize,
            status: getStatus,
            keyword: getKeyword,
            orderColumn: orderColumn,
            sortBy: sortBy
         });
      });
   };

   const columns = [{
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
         return (
            <>
               <Avatar src={record?.logo} size="large" gap="1" /> {record?.name} (
               {record?.symbol})
            </>
         );
      },
   }, {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text, record) => {
         return record?.category ? record?.category : "N/A";
      },
   }, {
      title: "First historical data",
      dataIndex: "first_historical_data",
      key: "first_historical_data",
      render: (text, record) => {
         return moment.utc(record?.first_historical_data).format(
            "DD/MM/YYYY HH:mm:ss A"
         );
      },
   }, {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
         return (
            <Space direction="vertical">
               <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={record?.status == "active" ? true : false}
                  onChange={(e) => changeCoinStatus(record?.id, e)}
               />
            </Space>
         );
      },
   }, {
      title: "Sync Crypto Pair",
      dataIndex: "status",
      key: "syncCryptoPair",
      render: (text, record) => {
         return (
            <Space direction="vertical">
               <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={record?.sync_crypto_pair == "1" ? true : false}
                  onChange={(e) => syncCryptoPair(record?.id, e)}
               />
            </Space>
         );
      },
   }, {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
         return (
            <>
               <Flex gap="small" align="flex-start" vertical>
                  <Button
                     type="primary"
                     onClick={() => {
                        syncCoinFn(record);
                     }}
                  >
                     Sync Coin Info
                  </Button>
                  <Button
                     type="primary"
                     onClick={() => {
                        syncHistoricalFn(record);
                     }}
                  >
                     Sync Historical
                  </Button>
               </Flex>
            </>
         );
      },
   }];

   const extraInfo = (
      coin
   ) => {
      const extraInfoData = [];
      /** description */
      if (coin?.description) {
         extraInfoData.push({
            key: `des_${coin?.id}`,
            label: "Description",
            children: coin?.description,
         });
      }

      /** urls */

      const coinUrls = coin?.urls;
      if (
         coinUrls &&
         Object.keys(coinUrls) &&
         Array.isArray(Object.keys(coinUrls))
      ) {
         let urlData = [];

         for (let urlKey of Object.keys(coinUrls)) {
            if (coinUrls[urlKey]?.length > 0) {
               urlData.push({
                  title:
                     urlKey == "technical_doc"
                        ? "Technical doc"
                        : urlKey == "message_board"
                           ? "message board"
                           : urlKey == "source_code"
                              ? "source code"
                              : urlKey,

                  avtarIcon:
                     (urlKey == urlKey) == "website" ? (
                        <GlobalOutlined />
                     ) : urlKey == "message_board" ? (
                        <MessageOutlined />
                     ) : urlKey == "explorer" ? (
                        <IeOutlined />
                     ) : urlKey == "reddit" ? (
                        <RedditOutlined />
                     ) : urlKey == "twitter" ? (
                        <TwitterOutlined />
                     ) : urlKey == "chat" ? (
                        <WechatOutlined />
                     ) : urlKey == "announcement" ? (
                        <NotificationOutlined />
                     ) : urlKey == "source_code" ? (
                        <InfoCircleOutlined />
                     ) : (
                        <InfoCircleOutlined />
                     ),
                  urls: (
                     <>
                        {Array.isArray(coinUrls[urlKey]) &&
                           coinUrls[urlKey].map((url, idx) => (
                              <Row>
                                 {" "}
                                 <Link to={url} target="_blank">
                                    {" "}
                                    {url}{" "}
                                 </Link>{" "}
                              </Row>
                           ))}
                     </>
                  ),
               });
            }
         }

         extraInfoData.push({
            key: `URLs_${coin?.id}`,
            label: "URLs",
            children: (
               <List
                  size="small"
                  dataSource={urlData}
                  renderItem={(item, index) => (
                     <List.Item key={index}>
                        <List.Item.Meta
                           avatar={<Avatar icon={item?.avtarIcon} />}
                           title={item?.title}
                           description={item?.urls}
                        />
                     </List.Item>
                  )}
               />
            ),
         });
      }

      /**** tokens */
      const tokens = coin?.contract_address;

      if (tokens && Object.keys(tokens) && Object.keys(tokens)?.length && Array.isArray(Object.keys(tokens))) {
         let tokenData = [];
         for (let token of tokens) {
            if (token?.contract_address) {
               tokenData.push(
                  <Text>
                     <Tooltip placement="topLeft" title={token?.platform?.name}>
                        <Avatar
                           style={{ marginRight: "4px" }}
                           icon={token?.platform?.name.charAt(0).toUpperCase()}
                        />
                     </Tooltip>
                     {token?.contract_address}
                  </Text>
               );
            }
         }

         extraInfoData.push({
            key: `conAdd_${coin?.id}`,
            label: "Contract address",
            children: (
               <Space size={[8, 16]} direction="horizontal" wrap>
                  {tokenData}
               </Space>
            ),
         });
      }

      /**** Fiat price data */
      // const coinsMarkets = coin?.coinsMarkets;
      // const priceQuote = coinsMarkets?.quote;
      // if (
      //    priceQuote &&
      //    Object.keys(priceQuote) &&
      //    Array.isArray(Object.keys(priceQuote))
      // ) {
      //    // let quoteData = [];
      //    for (let quoteKey of Object.keys(priceQuote)) {
      //       if (priceQuote[quoteKey]) {
      //          // const fiatColumns = [
      //          //    {
      //          //       title: "Price",
      //          //       dataIndex: "price",
      //          //       key: "price",
      //          //       render: (text, record) =>
      //          //          priceQuote[quoteKey]?.price === undefined
      //          //             ? priceQuote?.price
      //          //             : priceQuote[quoteKey]?.price,
      //          //    },
      //          //    {
      //          //       title: "Market Cap",
      //          //       dataIndex: "market_cap",
      //          //       key: "market_cap",
      //          //       render: (text) =>
      //          //          priceQuote[quoteKey]?.market_cap === undefined
      //          //             ? priceQuote?.market_cap
      //          //             : priceQuote[quoteKey]?.market_cap,
      //          //    },
      //          //    {
      //          //       title: "Synced At",
      //          //       dataIndex: "timestamp",
      //          //       key: "timestamp",
      //          //       render: (text) => {
      //          //          return (
      //          //             <>
      //          //                {priceQuote[quoteKey]?.last_updated
      //          //                   ? moment(priceQuote[quoteKey]?.last_updated).format(
      //          //                      // "MMM DD, YYYY hh:mm:ss A"
      //          //                      "DD/MM/YYYY HH:mm:ss A"
      //          //                   )
      //          //                   : ""}
      //          //             </>
      //          //          );
      //          //       },
      //          //    },
      //          // ];
      //          // quoteData.push(
      //          //    <>
      //          //       <div style={{ marginRight: "30px" }}>
      //          //          <Card size="small" title={quoteKey.toUpperCase()}>
      //          //             <Table
      //          //                style={{ height: "auto" }}
      //          //                size="small"
      //          //                columns={fiatColumns}
      //          //                pagination={false}
      //          //                dataSource={[priceQuote[quoteKey]]}
      //          //             />
      //          //          </Card>
      //          //       </div>
      //          //    </>
      //          // );
      //       }
      //    }

      //    // extraInfoData.push({
      //    //    key: `qutd_${coin?.id}`,
      //    //    label: "Fiat prices",
      //    //    children: (
      //    //       <Space size={[8, 16]} direction="horizontal" wrap>
      //    //          {quoteData}
      //    //       </Space>
      //    //    ),
      //    // });
      // }

      return (
         <Collapse className="coinListExtraInfo" accordion
            items={extraInfoData}
         />
      );
   };

   return (
      <>
         <Card
            className="coin_list_table"
            style={{
               border: "none",
               overflowX: 'scroll'
            }}
         >
            <Table
               className="coinListTable"
               columns={columns}
               dataSource={coinList}
               pagination={false}
               expandable={{
                  expandedRowRender: (record) => (
                     // <extraInfo coin={record} />
                     extraInfo(record)
                  ),
                  rowExpandable: (record) => record.name !== 'Not Expandable',
               }}
            />



         </Card>

      </>
   );
}
export default CoinListCompo;
