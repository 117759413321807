import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../common/constant/constant";

import { logout } from "../slice/user.slice";
import { startLoading, stopLoading } from "../slice/commonLoading.slice";
import { setToast } from "../slice/toast.slice";

export const coinsApi = createApi({
  reducerPath: "coinsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.ADMIN_API_URL,
    prepareHeaders: (headers, { getState, endpoints }) => {
      const { token } = getState()?.userState?.user;
      headers.set("Authorization", `${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    coinsList: builder.mutation({
      query: (payload) => ({
        method: "GET",
        url: `coins/list?page=${payload?.page}&limit=${payload?.limit}&keyword=${payload?.keyword}&status=${payload?.status}&orderColumn=${payload?.orderColumn}&sortBy=${payload?.sortBy}`,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(startLoading(true));
          let { data } = await queryFulfilled;
          data?.status && dispatch(stopLoading(false));
        } catch (error) {
          dispatch(stopLoading(false));
          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
    }),

    upadteCoinStatus: builder.mutation({
      query: (payload) => ({
        method: "post",
        url: `coins/updateStatus`,
        body: payload,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          let { data } = await queryFulfilled;
          dispatch(
            setToast({
              message: data?.message,
              success: true,
              error: false,
            })
          );
        } catch (error) {
          dispatch(
            setToast({
              message:
                error?.error?.data?.data[0]?.id?.isNotEmpty ||
                error.error?.data?.message ||
                "server error",
              success: false,
              error: true,
            })
          );

          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
    }),

    syncCmcCoins: builder.mutation({
      query: (payload) => ({
        method: "post",
        url: `/coins/syncCoinData`,
        body: payload,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(startLoading(true));
          let { data } = await queryFulfilled;

          dispatch(
            setToast({
              message: data?.message,
              success: true,
              error: false,
            })
          );
          dispatch(stopLoading(false));
        } catch (error) {
          dispatch(stopLoading(false));
          dispatch(
            setToast({
              message:
                error.error?.data?.data[0].id?.isNotEmpty || "server error",
              success: false,
              error: true,
            })
          );

          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
    }),

    syncCoinsList: builder.mutation({
      query: (payload) => ({
        method: "get",
        url: `/cmc/syncCoins`,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(startLoading(true));
          let { data } = await queryFulfilled;

          dispatch(
            setToast({
              message: data?.message,
              success: true,
              error: false,
            })
          );
          dispatch(stopLoading(false));
        } catch (error) {
          dispatch(stopLoading(false));
          dispatch(
            setToast({
              message: error.error?.data?.message || "server error",
              success: false,
              error: true,
            })
          );

          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
    }),

    syncCoinsInfo: builder.mutation({
      query: (payload) => ({
        method: "get",
        url: `/cmc/syncInfo`,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(startLoading(true));
          let { data } = await queryFulfilled;

          dispatch(
            setToast({
              message: data?.message,
              success: true,
              error: false,
            })
          );
          dispatch(stopLoading(false));
        } catch (error) {
          dispatch(stopLoading(false));
          dispatch(
            setToast({
              message: error.error?.data?.message || "server error",
              success: false,
              error: true,
            })
          );

          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
    }),

    upadteSyncCryptoPair: builder.mutation({
      query: (payload) => ({
        method: "post",
        url: `coins/upadteSyncCryptoPair`,
        body: payload,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          let { data } = await queryFulfilled;
          dispatch(
            setToast({
              message: data?.message,
              success: true,
              error: false,
            })
          );
        } catch (error) {
          dispatch(
            setToast({
              message:
                error?.error?.data?.data[0]?.id?.isNotEmpty ||
                error.error?.data?.message ||
                "server error",
              success: false,
              error: true,
            })
          );

          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
    }),

    syncCoinsHistory: builder.mutation({
      query: (payload) => ({
        method: "post",
        url: `/coins/updateHistorical`,
        body: payload,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          let { data } = await queryFulfilled;
          dispatch(
            setToast({
              message: data?.message,
              success: true,
              error: false,
            })
          );
        } catch (error) {
          dispatch(
            setToast({
              message:
                error?.error?.data?.data[0]?.id?.isNotEmpty ||
                error.error?.data?.message ||
                "server error",
              success: false,
              error: true,
            })
          );

          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
    }),
    
  }),
});

export const {
  useCoinsListMutation,
  useUpadteCoinStatusMutation,
  useSyncCmcCoinsMutation,
  useSyncCoinsInfoMutation,
  useSyncCoinsListMutation,
  // useSyncCryptoPairMutation
  // useUpadteSyncCryptoPairMutation
  useUpadteSyncCryptoPairMutation,
  useSyncCoinsHistoryMutation
} = coinsApi;
