import { Card, Table } from "antd";
import React, { useEffect } from "react";
import { useAlertsDataMutation } from "../../../redux/services/alertsApi";

function Alerts() {
  const [alertsData, { data: alertData }] = useAlertsDataMutation();
  const alertDataList = alertData?.data?.rows;
  useEffect(() => {
    alertsData();
  }, []);
  const columns = [{
    //   title: "id",
    //   dataIndex: "id",
    //   key: "id",
    // }, {
    title: "Price Source",
    dataIndex: "price_source",
    key: "price_source",
  }, {
    title: "Api Key",
    dataIndex: "api_key",
    key: "api_key",
  }, {
    title: "Error",
    dataIndex: "error",
    key: "error",
    // render: (text, record) => {
    //   return <span>{JSON.stringify(record.error)}</span>;
    // },

    // render: (error) => (
    //   <div>
    //     {Object.entries(error).map(([key, value]) => (
    //       <div key={key}>
    //         <strong>{key.replace(/_/g, ' ').toUpperCase()}:</strong> {value}
    //       </div>
    //     ))}
    //   </div>
    // ),

    render: (error) => (
      <div>
        {Object.entries(error).map(([key, keyValue]) => (
          <div key={key}>
            <strong>{key.replace(/_/g, ' ').toUpperCase()}:</strong>
            {typeof keyValue === 'object' && !Array.isArray(keyValue) ? (
              <ul>
                {JSON.stringify(keyValue)}

                {/* {Object.entries(keyValue).map(([child1_key, child1_v]) => (
                    <li key={child1_key}>
                      <strong>{child1_key.replace(/_/g, ' ').toUpperCase()}: </strong>
                      {typeof child1_v === 'object' && !Array.isArray(child1_v) ? (
                        <ul>
                          {Object.entries(child1_v).map(([child2_key, child2_v]) => (
                            <li key={child2_key}>
                              <strong>{child2_key.replace(/_/g, ' ').toUpperCase()}: </strong> {child2_v ? child2_v: 0 }
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <span> {JSON.stringify(child1_v)}</span>
                      )}
                    </li>
                  ))} */}
              </ul>
            ) : (
              <span> {JSON.stringify(keyValue)}</span>
            )}
          </div>
        ))}
      </div>
    ),
  },
  {
    title: "Created At",
    dataIndex: "created_at",
    key: "created_at",
    render: (text, record) => {
      return new Date(record?.created_at).toLocaleString();
    },
  },
  {
    title: "Updated At",
    dataIndex: "updated_at",
    key: "updated_at",
    render: (text, record) => {
      return new Date(record?.updated_at).toLocaleString();
    },
  }
  ];

  return (
    <div className="alert_page">
      <Card title="Alerts" style={{ border: "none" }} />
      <Table dataSource={alertDataList} columns={columns} pagination={false} />;
    </div>
  );
}

export default Alerts;
