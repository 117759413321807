import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import {
   Breadcrumb, Button,
   Card, Col,
   Form, Input,
   Pagination,
   Row, Space,
   Switch, Select,
   Table, Typography,
} from "antd";

import { useFiatListMutation, useSyncFiatListMutation, useUpadteFiatStatusMutation } from "../../../redux/services/fiatApi";

import { CheckOutlined, CloseOutlined, SyncOutlined } from "@ant-design/icons";

const { Option } = Select;

const defaultPageSize = 10;
const defaultCurrent = 1;

const FiatsListPage = () => {
   const { loading } = useSelector((state) => state?.loading);
   
   const formRef = React.useRef(null);

   const [currentPage, setCurrentPage] = useState(defaultCurrent);
   const [pageSize, setPageSize] = useState(defaultPageSize);
   const [searchKeyword, setSearchKeyword] = useState('');
   const [searchStatus, setSearchStatus] = useState('');

   const [fetchFiatsList, { data: getFiatList }] = useFiatListMutation();
   const [updateCmsKeyStatus] = useUpadteFiatStatusMutation();
   const [syncCmsFiatListStatus] = useSyncFiatListMutation();
   

   const onPageChange = (pageNumber, pageSize) => {
      fetchFiatsList({
         page: pageNumber,
         limit: pageSize,
         search: searchKeyword,
         status: searchStatus
      });

      setCurrentPage(pageNumber);
      setPageSize(pageSize);

   };

   const handleUpdateCmcFiatStatus = (
      id,
      status
   ) => {
      updateCmsKeyStatus({
         id: id,
         status: status == "active" ? "inactive" : "active",
      }).then(
         (resp) =>
            resp?.data?.status &&
            fetchFiatsList({
               page: currentPage,
               limit: pageSize,
               search: searchKeyword,
               status: searchStatus
            })
      );
   }

   const onFiatSearch = (searchKeywords) => {
      hitFiaListApi({
         page: currentPage,
         limit: pageSize,
         search: searchKeywords?.search || "",
         status: searchKeywords?.status || ""
      });

      setSearchKeyword(searchKeywords?.search || "")
      setSearchStatus(searchKeywords?.status || "")

   };

   const hitFiaListApi = (data) => {
      fetchFiatsList({
         page: data.page,
         limit: data.limit,
         search: data.search,
         status: data.status
      });
   }

   const syncFiatList = () => {
      syncCmsFiatListStatus();
   }

   useEffect(() => {
      hitFiaListApi({
         page: defaultCurrent,
         limit: defaultPageSize,
         search: searchKeyword,
         status: searchStatus
      });

      setCurrentPage(defaultCurrent);
      setPageSize(defaultPageSize);


   }, []);

   const columns = [{
      title: "Sign",
      dataIndex: "sign",
      key: "sign",
      render: (text, record) => {
         return record?.sign ? record?.sign : "N/A";
      }
   }, {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      render: (text, record) => {
         return record?.symbol ? record?.symbol : "N/A";
      }
   }, {
      title: "Name",
      dataIndex: "name",
      key: "name",
   }, {
      title: "Action",
      // dataIndex: "action",
      key: "action",
      render: (text, record, index) => {
         return (
            <Space >
               <Switch
                  key={"cmcKey_" + record?.id}
                  name={"cmcKey_" + record?.id}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  // checkedChildren={"CMC"+ <CheckOutlined />} 
                  // unCheckedChildren="CMC deactived"
                  checked={record?.status == "active" ? true : false}
                  onChange={(e) => {
                     handleUpdateCmcFiatStatus(record?.id, record?.status);
                  }}
               />
            </Space>
         );
      },


      dataIndex: '',
      // key: 'x',
      // render: (text, record) => {
      //    <a>Invite {record?.symbol}</a>
      //    // <Space size="middle">

      //    //    <a>Delete</a>
      //    // </Space>
      // }

   }];

   return (
      <>
         <Card>
            <Breadcrumb items={[{ title: "Dashboard", }, { title: "Fiat Currency List", }]} />
         </Card>
         <Card title="All Fiat Currency List" key="AllCoinList"
            extra={
               <>
                  <Row>
                     <Button
                        type="primary"
                        onClick={() => syncFiatList("syncInfo")}
                     >
                        <SyncOutlined /> Sync Fiats
                     </Button>

                     {/* <Col md={12}>
                        <Button type="primary" onClick={() => setIsModalOpen(true)}>
                           Add Token
                        </Button>
                     </Col> */}
                     {/* <Col md={3}>
                        
                     </Col> */}
                  </Row>
               </>
            }
            style={{ border: "none" }}
         >
            <Form
               ref={formRef}
               name="control-ref"
               onFinish={onFiatSearch}
            >
               <Row gutter={20}>
                  <Col xs={24} sm={12} md={12} lg={6}>
                     <Form.Item name="search">
                        <Input placeholder="Search name" allowClear />
                     </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={6} >
                     <Form.Item name="status" >
                        <Select placeholder="Search Status" allowClear >
                           <Option value="active"> Active </Option>
                           <Option value="inactive"> Inactive</Option>
                        </Select>
                     </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={12} lg={9}>
                     <Form.Item className="searchresetbtn">
                        <Button type="primary" htmlType="submit">
                           Search
                        </Button>
                        <Button htmlType="button"
                           // onClick={onReset}
                           type="primary" danger>
                           Reset
                        </Button>
                     </Form.Item>
                  </Col>
               </Row>
            </Form>

         </Card>

         <Table
            className="fiatCurrencyListTable"
            // style={{ overflowY: "scroll" }}
            pagination={false}
            columns={columns}
            dataSource={getFiatList?.data?.rows}
            loading={loading}
         />

         <Row className="PaginationRow justifyContentCenter">
            {getFiatList?.data?.count >= 10 && (
               <Pagination
                  showQuickJumper
                  // defaultPageSize={defaultPageSize}
                  // defaultCurrent={defaultCurrent}
                  current={currentPage}
                  total={getFiatList?.data?.count}
                  pageSize={pageSize}
                  onChange={onPageChange}
                  responsive={true}
               />
            )}
         </Row>
      </>
   );
};
export default FiatsListPage;
