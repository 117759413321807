import React from 'react';
import { Card, Flex, Progress } from 'antd';
import { Link } from 'react-router-dom';
import "../../../../index.scss";
import { CheckCircleTwoTone, StopTwoTone } from '@ant-design/icons';

const conicColors = {
   '0%': '#237804',
   '20%': '#a0d911',
   '40%': '#ffec3d',
   '60%': '#faad14',
   '80%': '#fa8c16',
   '100%': '#f5222d',
};
const CmcKeysUsageDashboard = ({
   plan_type,
   cmcKeysUsage,
   className
}) => {
   const usagePercentage = ((cmcKeysUsage?.currentMonthRequestsMade / cmcKeysUsage?.creditLimitMonthly) * 100) || 0;

   const statusIcon = 'exception';

   return (
      <>
         <Card
            size="small"
            className={className}
            // style={{ minHeight: '400px' }}
            title={plan_type}
            extra={
               <Link className="linkButton" to="/price-source/cmc/keylist">
                  Show more
               </Link>
            }
         >
            <p gap="small" className='justifyContentCenter displayFlex flex-wrap-wrap' wrap>
               <Progress
                  type="circle"
                  percent={usagePercentage.toFixed(2)}
                  strokeColor={conicColors}
                  showInfo={true}
                  status={statusIcon}
                  format={(usagePercentage) => `${usagePercentage}%`}
                  strokeWidth={10}
                  size={140}
               />
            </p>
            
            <p size="small" style={{ fontSize: '13px' }}>
               <p size="small"> <b> Platform Status:</b> {cmcKeysUsage?.cmc_key_status?.toUpperCase()} {cmcKeysUsage?.cmc_key_status == 'active' ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <StopTwoTone twoToneColor="#eb2f96" />}  </p>
               <p size="small"> <b> Active Key Count:</b> {cmcKeysUsage?.activeKeyCount} </p>
               <p size="small"> <b> Current Month Usage:</b> {cmcKeysUsage?.currentMonthRequestsMade} </p>
               <p size="small"> <b> Monthly Credit Limit:</b> {cmcKeysUsage?.creditLimitMonthly} </p>
            </p>

            <div className='key_usage_card_footer' style={{ borderTop: '1px solid #f0f0f0', marginTop: 16, paddingTop: 8, fontSize: '13px' }}>
               <b> Last updated at:</b> {cmcKeysUsage?.last_updated_at}
            </div>
         </Card>
      </>
   );
}
export default CmcKeysUsageDashboard;

