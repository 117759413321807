import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";

import { CONFIG } from "../../common/constant/constant";

import { setToast } from "../slice/toast.slice";
import { setUser } from "../slice/user.slice";

export const loginApi = createApi({
  reducerPath: "login",

  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.ADMIN_API_URL,
    prepareHeaders: (headers, { getState, endpoints }) => {
      return headers;
    },
  }),
  endpoints: (builder) => ({
    logins: builder.query({
      query: () => "users/hadley/orgs",
    }),

    loginAuth: builder.mutation({
      query: (loginData) => ({
        url: "auth/login",
        method: "POST",
        body: loginData,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data?.status) {
            localStorage.setItem("adminToken", data?.data?.token);
            localStorage.setItem("adminName", data?.data?.name);
            localStorage.setItem("adminEmail", data?.data?.email);

            dispatch(
              setToast({
                message: data?.message,
                success: true,
                error: false,
              })
            );
          }
          dispatch(setUser(data));
        } catch (error) {
          dispatch(
            setToast({
              message: error?.error?.data?.message || "server error",
              success: false,
              error: true,
            })
          );
        }
      },

      async onCacheEntryAdded(arg, { dispatch }) { },
    }),

    forgotPass: builder.mutation({
      query: (loginData) => ({
        url: "security/forgotPassword",
        method: "POST",
        body: loginData,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.status) {
            dispatch(
              setToast({
                message: data?.message,
                success: true,
                error: false,
              })
            );
          }
          dispatch(setUser(data));
        } catch (error) {
          dispatch(
            setToast({
              message: error?.error?.data?.message || "server error",
              success: false,
              error: true,
            })
          );
        }
      },
      async onCacheEntryAdded(arg, { dispatch }) { },
    }),

    resetPass: builder.mutation({
      query: (loginData) => ({
        url: "resetPass",
        method: "POST",
        body: loginData,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.status) {
            toast.success(data?.message, { autoClose: 2000 });
          }
          dispatch(setUser(data));
        } catch (error) {
          toast.error(error?.error?.data?.message, { autoClose: 2000 });
        }
      },
      async onCacheEntryAdded(arg, { dispatch }) { },
    }),
  }),
});

export const {
  useLoginAuthMutation,
  useForgotPassMutation,
  useResetPassMutation,
} = loginApi;
