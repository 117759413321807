import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../common/constant/constant";

import { toast } from "react-toastify";
import { logout } from "../../redux/slice/user.slice";

const adminToken = localStorage.getItem("adminToken")
   ? localStorage.getItem("adminToken")
   : null;

export const projectApi = createApi({
   reducerPath: "projectApi",
   baseQuery: fetchBaseQuery({
      baseUrl: CONFIG.API_URL,
      prepareHeaders: (headers, { getState, endpoints }) => {
         headers.set("Authorization", `${adminToken}`);
         return headers;
      },
   }),
   endpoints: (builder) => ({
      projectData: builder.mutation({
         query: (payload) => ({
            method: "GET",
            url: `projects/${payload}`,
         }),
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               let { data } = await queryFulfilled;
            } catch (error) {
               toast.error(error?.error?.data?.message, { autoClose: 2000 });

               if(error?.error?.status == 401){
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);   
               }
            }
         },
      }),

      addProject: builder.mutation({
         query: (payload) => ({
            url: "projects/addNew",
            method: "POST",
            body: payload,
         }),
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               const { data } = await queryFulfilled;
               if (data?.status) {
                  toast.success(data?.message, { autoClose: 2000 });
               }
            } catch (error) {
               toast.error(error?.error?.data?.message, { autoClose: 2000 });

               if(error?.error?.status == 401){
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);   
               }
            }
         },

         async onCacheEntryAdded(arg, { dispatch }) { },
      }),

      
   }),
});

export const {
   useProjectDataMutation,
   useAddProjectMutation
} = projectApi;
