import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import "./index.scss";


import LoginPage from "./pages/public/login.page";
import ForgotPassPage from "./pages/public/forgot-password.page";
import ResetPassPage from "./pages/public/reset-password.page";

import DashboardPage from "./pages/authorized/dashboard/dashboard.page";

import SecuritySettingsPage from "./pages/authorized/settings/security.page";
import UserNotificationAlertSettingsPage from "./pages/authorized/settings/userNotificationAlert.page";

import CoinsListPage from "./pages/authorized/coins/coinsList.page";
import CoinsSyncPage from "./pages/authorized/coins/coinsSync.page";

import FiatsListPage from "./pages/authorized/fiats/fiatList.page";

import VendersPage from "./pages/authorized/vender/vendersList.page";
import VenderDetailsPage from "./pages/authorized/vender/venderDetails.page";

import Alerts from "./pages/authorized/alerts/alerts";

import CmcKeyListPage from "./pages/authorized/price-source/cms/cmcKeyList.page";

import PlansList from "./pages/authorized/apiHitsPlansList/plansList.page";

import { RestrictedRoutes } from "./Routes/RestrictedRoutes";
import { PrivateRoutes } from "./Routes/PrivateRoutes";
import { CustomToast } from "./common/CommonToast/toast";

function App() {
   const { success, message, error } = useSelector((state) => state?.toastState);

   return (
      <>
         <CustomToast success={success} error={error} message={message} />
         <Routes>

            <Route path="/" element={<RestrictedRoutes />}>
               <Route path="/" element={<LoginPage />} />
               <Route path="/forgot-password" element={<ForgotPassPage />} />
               <Route path="/reset-pass/:id" element={<ResetPassPage />} />
            </Route>

            <Route path="/" element={<PrivateRoutes />}>
               <Route path="/dashboard" index element={<DashboardPage />} />

               <Route path="/venders-list" element={<VendersPage />} />
               <Route path="/vender-details/:id" element={<VenderDetailsPage />} />

               <Route path="/price-source/cmc/keylist" element={<CmcKeyListPage />} />

               <Route path="/coin-list" element={<CoinsListPage />} />
               <Route path="/fiat-list" element={<FiatsListPage />} />

               <Route path="/coins/sync" element={<CoinsSyncPage />} />
               <Route path="/planslist" element={<PlansList />} />
               <Route path="/alerts" element={<Alerts />} />

               <Route path="/settings/security" element={<SecuritySettingsPage />} />
               <Route path="/settings/users-alert" element={<UserNotificationAlertSettingsPage />} />

            </Route>
         </Routes>
      </>
   );
}
export default App;
