import React from "react";
import { Col, Modal, Row } from "antd";


const ConfirmModal = ({ showModal, setShowModal, onUpdate, userData }) => {
  return (
    <>
      <Modal
        id="logoutModal"
        title={`Are you sure want to  ${userData?.text}? `}
        centered
        open={showModal}
        onOk={() => onUpdate()}
        onCancel={() => setShowModal(false)}
        width={500}
        okText={"Yes"}
        cancelText={"No"}
      >
        <Row>
          <Col md={24}>
            <b>Name : </b> {userData?.name}
          </Col>
          <Col md={24}>
            <b>Email: </b> {userData?.email}
          </Col>
        </Row>
      </Modal>
    </>
  );
}
export default ConfirmModal;
