import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";

const { Option } = Select

const CoinsFilter = ({
  reseteCoinSearchKeywordFn,
  coinSearchKeywordFn
}) => {
  const [coinValue, setCoinValue] = useState("");

  const formRef = React.useRef(null);

  const onFinish = (values) => {
    (values?.name || values?.status || values?.orderColumn || values?.sortBy) && coinSearchKeywordFn(
      values?.name || "",
      values?.status || "",
      values?.orderColumn || "",
      values?.sortBy || ""
    );
  };

  const onReset = () => {
    setCoinValue("");
    formRef.current?.resetFields();
    reseteCoinSearchKeywordFn();
  };

  const srchFromInitialValues = {
    orderColumn: 'cmc_id',
    sortBy: 'asc'
  };


  return (
    <div className="coinListPage">
      <Form
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        onChange={(e) => setCoinValue(e.target.value)}
        initialValues={srchFromInitialValues}
      >
        <Row gutter={20} className="p2">
          <Col xs={24} sm={12} md={6} lg={6}>
            <Form.Item name="name">
              <Input placeholder="Search name" allowClear />
            </Form.Item>
          </Col>

          <Col xs={24} sm={9} md={4} lg={3} >
            <Form.Item name="status" >
              <Select placeholder="Search Status" allowClear >
                <Option value="active"> Active </Option>
                <Option value="inactive">Inactive</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={9} md={4} lg={3} >
            <Form.Item name="orderColumn" >
              <Select placeholder="Order By">
                <Option value="cmc_id"> CMC ID </Option>
                <Option value="name"> Name </Option>
                <Option value="symbol"> Symbol </Option>
                <Option value="sync_crypto_pair"> Sync Crypto Pair </Option>
                <Option value="created_at"> Added At </Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={9} md={4} lg={3}>
            <Form.Item name="sortBy" >
              <Select placeholder="Sort">
                <Option value="asc"> ASC </Option>
                <Option value="desc">DESC</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={6} lg={6}>
            <Form.Item className="searchresetbtn">
              <Button type="primary" htmlType="submit">
                Search
              </Button>
              <Button htmlType="button" onClick={onReset} type="primary" danger>
                Reset
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default CoinsFilter;
