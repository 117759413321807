import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../common/constant/constant";
import { toast } from "react-toastify";
import { logout } from "../slice/user.slice";
import { setToast } from "../slice/toast.slice";
import { startLoading, stopLoading } from "../slice/commonLoading.slice";

const moduleSlug = "vender/";

export const venderApi = createApi({
   reducerPath: "venderApi",
   baseQuery: fetchBaseQuery({
      baseUrl: CONFIG.ADMIN_API_URL,
      prepareHeaders: (headers, { getState, endpoints }) => {
         const { token } = getState()?.userState?.user;
         headers.set("Authorization", `${token}`);
         return headers;
      },
   }),
   tagTypes: ["vender"],
   endpoints: (builder) => ({

      updateVender: builder.mutation({
         query: (payload) => ({
            url: `${moduleSlug}update`,
            method: "POST",
            body: payload,
         }),
         tagTypes: ["vender"],
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               const { data } = await queryFulfilled;
               if (data?.status) {
                  toast.success(data?.message, { autoClose: 2000 });
               }
            } catch (error) {
               toast.error(error?.error?.data?.message, { autoClose: 2000 });

               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);
               }
            }
         },

         async onCacheEntryAdded(arg, { dispatch }) { },
      }),

      venderList: builder.mutation({
         query: (payload) => ({
            method: "GET",
            url: `${moduleSlug}list?keyword=${payload?.keyword}&status=${payload?.status}&limit=${payload?.limit}&page=${payload?.page}`,
         }),
         providesTags: ["vender"],

         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               dispatch(startLoading(true));
               let { data } = await queryFulfilled;

               data?.status && dispatch(stopLoading(false));
            } catch (error) {
               dispatch(stopLoading(false));
               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);
               }
            }
         },
      }),

      venderStatusUpdate: builder.mutation({
         query: (payload) => ({
            method: "post",
            url: `${moduleSlug}updateRequest/status`,
            body: payload,
         }),
         invalidatesTags: ["vender"],
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               let { data } = await queryFulfilled;

               dispatch(
                  setToast({
                     message: data?.message,
                     success: true,
                     error: false,
                  })
               );
            } catch (error) {
               dispatch(
                  setToast({
                     message:
                        error?.error?.data?.data[0]?.status?.isNotEmpty ||
                        "server error",
                     success: false,
                     error: true,
                  })
               );

               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout(null));
                  }, 2000);
               }
            }
         },
      }),

      venderInfo: builder.mutation({
         query: (payload) => ({
            method: "GET",
            url: `${moduleSlug}details/${payload?.id}`
         }),
         providesTags: ["vender"],
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               let { data } = await queryFulfilled;

               dispatch(
                  setToast({
                     message: data?.message,
                     success: true,
                     error: false,
                  })
               );
            } catch (error) {
               dispatch(
                  setToast({
                     message:
                        error?.error?.data?.data[0]?.status?.isNotEmpty ||
                        "server error",
                     success: false,
                     error: true,
                  })
               );

               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout(null));
                  }, 2000);
               }
            }
         },
      }),

      upgradePlanApi: builder.mutation({
         query: (payload) => {
            return {
               method: "post",
               url: `${moduleSlug}upgradePlan`,
               body: payload,
            };
         },
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               dispatch(startLoading(true));
               let { data } = await queryFulfilled;

               dispatch(
                  setToast({
                     message: data?.message,
                     success: true,
                     error: false,
                  })
               );
            } catch (error) {
               dispatch(stopLoading(false));
               dispatch(
                  setToast({
                     message: error?.error?.data?.message || "server error",
                     success: false,
                     error: true,
                  })
               );
               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);
               }
            }
         },
      }),

      updateSettings: builder.mutation({
         query: (payload) => {
            return {
               method: "post",
               url: `${moduleSlug}updateSettings`,
               body: payload,
            };
         },
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               dispatch(startLoading(false));
               let { data } = await queryFulfilled;

               dispatch(
                  setToast({
                     message: data?.message,
                     success: true,
                     error: false,
                  })
               );
            } catch (error) {
               dispatch(stopLoading(false));
               dispatch(
                  setToast({
                     message: error?.error?.data?.message || "server error",
                     success: false,
                     error: true,
                  })
               );
               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);
               }
            }
         },
      }),

      addNewVender: builder.mutation({
         query: (payload) => {
            return {
               method: "post",
               url: `${moduleSlug}addNewVender`,
               body: payload,
            };
         },
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               dispatch(startLoading(false));
               let { data } = await queryFulfilled;

               dispatch(
                  setToast({
                     message: data?.message,
                     success: true,
                     error: false,
                  })
               );
            } catch (error) {
               dispatch(stopLoading(false));
               dispatch(
                  setToast({
                     message: error?.error?.data?.message || "server error",
                     success: false,
                     error: true,
                  })
               );
               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);
               }
            }
         },
      }),

      updatePlanValidity: builder.mutation({
         query: (payload) => {
            return {
               method: "post",
               url: `${moduleSlug}updatePlanValidity`,
               body: payload,
            };
         },
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               dispatch(startLoading(false));
               let { data } = await queryFulfilled;

               dispatch(
                  setToast({
                     message: data?.message,
                     success: true,
                     error: false,
                  })
               );
            } catch (error) {
               dispatch(stopLoading(false));
               dispatch(
                  setToast({
                     message: error?.error?.data?.message || "server error",
                     success: false,
                     error: true,
                  })
               );
               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout());
                  }, 2000);
               }
            }
         },
      }),

      creditUsage: builder.mutation({
         query: (payload) => ({
            method: "GET",
            url: `${moduleSlug}creditUsage/${payload?.id}?filterType=${payload?.filterType}`
         }),
         providesTags: ["vender"],
         transformResponse: (result) => result,
         async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
               let { data } = await queryFulfilled;

               dispatch(
                  setToast({
                     message: data?.message,
                     success: true,
                     error: false,
                  })
               );
            } catch (error) {
               dispatch(
                  setToast({
                     message:
                        error?.error?.data?.data[0]?.status?.isNotEmpty ||
                        "server error",
                     success: false,
                     error: true,
                  })
               );

               if (error?.error?.status == 401) {
                  setTimeout(() => {
                     dispatch(logout(null));
                  }, 2000);
               }
            }
         },
      }),

   }),
});

export const {
   useUpdateVenderMutation,
   useVenderListMutation,
   useVenderStatusUpdateMutation,
   useVenderInfoMutation,
   useUpgradePlanApiMutation,
   useUpdateSettingsMutation,
   useAddNewVenderMutation,
   useUpdatePlanValidityMutation,
   useCreditUsageMutation
} = venderApi;
