import { Button, DatePicker, Divider, Form, Modal, Space } from "antd";
import { useEffect, useState } from "react";

import moment from "moment";

import { useUpdatePlanValidityMutation } from "../../../../redux/services/venderApi";
import { EditFilled } from "@ant-design/icons";

const UpdatePlanDate = ({
   vender,
   setPlanValidityDate,
   getPlanValidityDate
}) => {
   const [updatePlanValidity] = useUpdatePlanValidityMutation();

   const [updatePlanValidityFrom] = Form.useForm();

   const [getUpdatePlanValidityModelOpen, setUpdatePlanValidityModelOpen] = useState(false);
   const [getVenderId, setVenderId] = useState(0);
   // const [getPrefillDate, setPrefillDate] = useState();


   /** Function to disable past dates */
   const disabledDate = (current) => {
      /** Can not select days before today and today */
      // return current && current < moment().startOf('day');
   };

   const onResetUpdatePlanValidityFrom = () => {
      updatePlanValidityFrom.resetFields();
   };

   const onFinishUpdatePlanValidity = (values) => {
      let plan_valid_up_to = values.plan_valid_up_to.endOf('day');

      values = {
         ...values,
         id: getVenderId,
         plan_valid_up_to: plan_valid_up_to
      }

      updatePlanValidity(values).then((resp) => {

         /**  */
         if (resp?.data?.status && resp?.data?.message) {
            setUpdatePlanValidityModelOpen(false);
            onResetUpdatePlanValidityFrom();
            setPlanValidityDate(plan_valid_up_to.format("DD-MMM-YYYY - HH:mm:ss"))
         }
      });
   }

   useEffect(() => {
      setVenderId(vender?.id);


   }, [vender])

   return (
      <>
         <Button
            key="updatePlanValidityBtn"
            type="primary"
            onClick={() => {
               setUpdatePlanValidityModelOpen(true)
            }}
         >
            <EditFilled width="3em" />
         </Button>

         <Modal
            title="Update Plan Veild Upto"
            centered
            open={getUpdatePlanValidityModelOpen}
            onOk={() => setUpdatePlanValidityModelOpen(false)}
            onCancel={() => setUpdatePlanValidityModelOpen(false)}
            width={550}
            id="logoutModal"
            okText={"Yes"}
            cancelText={"No"}
            footer={null}

         >
            <Divider />

            <Form
               layout="vertical"
               form={updatePlanValidityFrom}
               name="updatePlanValidityFrom"
               onFinish={onFinishUpdatePlanValidity}
               // initialValues={{
               //    plan_valid_up_to: getPrefillDate
               // }}
            >
               <Form.Item
                  name="plan_valid_up_to" label="Plan Valid Up To"
                  rules={[{ required: true, message: 'Please select Valid Date!' }]}
               >
                  <DatePicker disabledDate={disabledDate} style={{ width: '100%' }} />
               </Form.Item>

               <Form.Item >
                  <Space>
                     <Button type="primary" htmlType="submit">
                        Update Now
                     </Button>
                     <Button htmlType="button" onClick={onResetUpdatePlanValidityFrom}>
                        Reset
                     </Button>
                  </Space>
               </Form.Item>

            </Form>

         </Modal>
      </>
   );
}
export default UpdatePlanDate;
