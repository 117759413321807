import { Col, Switch } from "antd";
import { useEffect, useState } from "react";

import { useUpdateSettingsMutation } from "../../../../redux/services/venderApi";


const SettingsBtn = ({
   vender_id,
   btn_key,
   btn_value
}) => {
   const [updateSettings] = useUpdateSettingsMutation();

   const btnTitle = btn_key == 'allow_add_custom_token' ? 'Add Custom Token' : '';

   const [getBtnKey, setBtnKey] = useState();
   const [getBtnValue, setBtnValue] = useState();


   const handleUpdateSettings = (settingValue) => {
      settingValue = settingValue == true ? "1" : "0";
      updateSettings({
         id: vender_id,
         value: settingValue == true ? "1" : "0",
         key: getBtnKey
      }).then((resp) => {
         setBtnValue(settingValue);
      });
   }

   useEffect(() => {
      setBtnKey(btn_key);
      setBtnValue(btn_value);

   }, [btn_key, btn_value]);

   return (
      <>
         <Col col={24}>
            <b> {btnTitle} : </b>
            <Switch
               name={btn_key}
               size="large"
               checkedChildren="Allowed"
               unCheckedChildren="Denied"
               checked={getBtnValue == '1' ? true : false}
               // danger={cmcStatus != 'active' ? true : false}
               onClick={(e) => {
                  handleUpdateSettings(e);
               }}
            />

         </Col>
      </>
   );
}
export default SettingsBtn;