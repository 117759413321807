import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Divider, Form, Input, Modal, Select, Space, Switch, Table } from "antd";
import { useSelector } from "react-redux";

import {
   useGeneRateCmcKeyMutation,
   useKeysListMutation,
   useUpdateCmsKeyStatusMutation,
   useUpdateCmsStatusMutation
} from "../../../../redux/services/cmcApi";

import { dateformat } from "../../../../common/commonFunction";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import KeyDetailsComp from "./component/keyDetails.comp";
import KeyFiltersComop from "./keyFilter.comp";

const defaultCurrent = 1;
const defaultPageSize = 100;

const CmcKeyListPage = () => {
   const [resetForm, setResetForm] = useState(false);
   const { success } = useSelector((state) => state?.toastState);
   const [newKeyModalOpen, setnewKeyModalOpen] = useState(false);
   const [planInfoModalOpen, setplanInfoModalOpen] = useState(false);
   const [addcmcKey, setAddCmcKey] = useState("");
   const [cmcKey, setCmcKey] = useState("");
   const { loading } = useSelector((state) => state?.loading);

   const [currentPage, setCurrentPage] = useState(1);
   const [pageSize, setPageSize] = useState(100);
   const [getStatus, setStatus] = useState("");
   const [getSearch, setSearch] = useState("");
   const [getOrderColumn, setOrderColumn] = useState("");
   const [getSortBy, setSortBy] = useState("");
   const [getPlanType, setPlanType] = useState("");


   const [geneRateCmcKey] = useGeneRateCmcKeyMutation();
   // const [updateCmsStatus] = useUpdateCmsStatusMutation();
   const [updateCmsKeyStatus] = useUpdateCmsKeyStatusMutation();
   const [keysList, { data }] = useKeysListMutation();

   const [addNewCmcKeyForm] = Form.useForm();

   const state = useSelector((data) => data?.addcmcSlice?.cmsList);
   // const cmcStatus = state?.status;

   const showModal = () => {
      setnewKeyModalOpen(true);
   };

   const onFinish = (values) => {
      geneRateCmcKey(values).then((resp) => {
         if (resp?.data?.status) {
            addNewCmcKeyForm.resetFields();
            setResetForm(true);
            setAddCmcKey("");
            setnewKeyModalOpen(false);

            keysList({
               page: currentPage,
               limit: pageSize,
               status: getStatus,
               search: getSearch,
               orderColumn: getOrderColumn,
               sortBy: getSortBy,
               plan_type: getPlanType
             });
         }
      });
   };


   // const handleUpdateCmcStatus = (cmcStatus) => {
   //    updateCmsStatus({
   //       status: cmcStatus == "active" ? "inactive" : "active",
   //    }).then((resp) => {
   //       // resp?.data?.status && 
   //       keysList({
   //          page: currentPage,
   //          limit: pageSize,
   //          status: getStatus,
   //          search: getSearch,
   //          orderColumn: getOrderColumn,
   //          sortBy: getSortBy,
   //          plan_type: getPlanType
   //        });
   //    });
   // }

   const onResetAddNewVenderFrom = () => {
      addNewCmcKeyForm.resetFields();
   };

   useEffect(() => {
      keysList({
         page: currentPage,
         limit: pageSize,
         status: getStatus,
         search: getSearch,
         orderColumn: getOrderColumn,
         sortBy: getSortBy,
         plan_type: getPlanType
       });
   }, []);

   useEffect(() => {
      addcmcKey !== "" && setResetForm(false);
   }, [success, addcmcKey]);

   // const onPageChange = (pageNumber, pageSize) => {
   //    setCurrentPage(pageNumber);
   //    setPageSize(pageSize);

   //    keysList({
   //       page: currentPage,
   //       limit: pageSize,
   //       status: getStatus,
   //       search: getSearch,
   //       orderColumn: getOrderColumn,
   //       sortBy: getSortBy,
   //       plan_type: getPlanType
   //     });
   // };

   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 100, // Default page size
   });

   const handleTableChange = (pagination) => {
      setPagination(pagination);
   };

   const handleUpdateCmcKeyStatus = (id, status) => {
      updateCmsKeyStatus({
         id: id,
         status: status == "active" ? "inactive" : "active",
      }).then(
         (resp) =>
            resp?.data?.status &&
            keysList({
               page: currentPage,
               limit: pageSize,
               status: getStatus,
               search: getSearch,
               orderColumn: getOrderColumn,
               sortBy: getSortBy,
               plan_type: getPlanType
            })
      );

   }

   const columns = [{
      title: 'Sr. No.',
      dataIndex: 'srNo',
      render: (_, __, index) =>
         (pagination.current - 1) * pagination.pageSize + index + 1,
   }, {
      title: "Keys",
      dataIndex: "cmc_key",
      key: "cmc_key",
   }, {
      title: "Label",
      dataIndex: "name",
      key: "name",
   }, {
      title: "Plan Type",
      dataIndex: "plan_type",
      key: "plan_type",
   }, {
      title: "Status",
      dataIndex: "status",
      key: "status",
   }, {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text, record) => {
         return dateformat(record.last_expired_at);
      },
   }, {
      title: "Plans details",
      render: (text, record) => {
         return (
            <Button
               type="primary"
               onClick={() => {
                  setCmcKey(record?.cmc_key);
                  setplanInfoModalOpen(true);
               }}
            >
               Plans
            </Button>
         );
      },
   }, {
      title: "Actions",
      dataIndex: "",
      key: "updated_at",
      render: (text, record) => {
         return (
            <>
               <Switch
                  key={"cmcKey_" + record?.id}
                  name={"cmcKey_" + record?.id}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={record?.status == "active" ? true : false}
                  onChange={(e) => {
                     handleUpdateCmcKeyStatus(record?.id, record?.status);
                  }}
               />
            </>
         );
      },
   }];

   const reseteKeySearchKeywordFn = () => {
      setStatus('');
      setSearch('');
      setOrderColumn('');
      setSortBy('');
      setPlanType('');

      keysList({
         page: currentPage,
         limit: pageSize,
         status: getStatus,
         search: getSearch,
         orderColumn: getOrderColumn,
         sortBy: getSortBy,
         plan_type: getPlanType
      });
   };

   const keySearchKeywordFn = (search, status, orderColumn, sortBy, plan_type) => {
      setStatus(status);
      setSearch(search);
      setOrderColumn(orderColumn);
      setSortBy(sortBy);
      setPlanType(plan_type);

      keysList({
         page: currentPage,
         limit: pageSize,
         status: status || "",
         search: search || "",
         orderColumn: orderColumn || "",
         sortBy: sortBy || "",
         plan_type: plan_type || ""
      });
   };


   return (
      <>
         <Card>
            <Breadcrumb items={[{ title: "Dashboard" }, { title: "Cmc Keys" }]} />
         </Card>

         <Card title="Cmc Keys" style={{ border: "none" }} extra={
            <>
               <Space>
                  {/* <Switch
                     name="cmc_status"
                     size="large"
                     checkedChildren="CMC active"
                     unCheckedChildren="CMC deactived"
                     checked={cmcStatus != 'active' ? false : true}
                     onClick={(e) => {
                        handleUpdateCmcStatus(cmcStatus);
                     }}
                  /> */}

                  <Button type="primary" onClick={showModal}>
                     Add New Key
                  </Button>
               </Space>
            </>
         }>
            <KeyFiltersComop
               reseteKeySearchKeywordFn={reseteKeySearchKeywordFn}
               keySearchKeywordFn={keySearchKeywordFn}
            />

         </Card>



         <Table
            dataSource={state?.key_list?.rows}
            columns={columns}
            loading={loading}
            pagination={{ ...pagination, pageSizeOptions: [10, 20, 50], showSizeChanger: true }}
            onChange={handleTableChange}
         />

         <KeyDetailsComp
            planInfoModalOpen={planInfoModalOpen}
            setplanInfoModalOpen={setplanInfoModalOpen}
            cmcKey={cmcKey}
         />


         <Modal
            title="Add Cmc Key Details"
            open={newKeyModalOpen}
            onOk={() => setnewKeyModalOpen(false)}
            onCancel={() => setnewKeyModalOpen(false)}
            okText={"Yes"}
            cancelText={"No"}
            footer={null}
            centered
            id="addCmcKeyModal"
         >
            <Divider />

            <Form
               name="control-ref"
               form={addNewCmcKeyForm}
               onFinish={onFinish}
               labelCol={{
                  flex: '90px',
               }}
               labelAlign="left"
               labelWrap
            >
               <Form.Item label="Key" name="key" rules={[
                  { required: true, message: 'Please enter cmc key!' },
               ]}
               >
                  <Input />
               </Form.Item>

               <Form.Item
                  name="plan_type"
                  label="Plan Type"
                  rules={[{ required: true, message: 'Please Select A Plan!' }]}
               >

                  <Select name="plan_type" allowClear placeholder="Select Plan Type">
                     <Select.Option value="free">Free</Select.Option>
                     <Select.Option value="payed">Payed</Select.Option>
                  </Select>

               </Form.Item>

               <Form.Item label="Name" name="name" allowClear rules={[
                  { required: true, type: 'string', message: 'Please input valid Name!' },
               ]}
               >
                  <Input />
               </Form.Item>

               <Form.Item >
                  <Space>
                     <Button type="primary" htmlType="submit">
                        Add Now
                     </Button>
                     <Button htmlType="button" onClick={onResetAddNewVenderFrom}>
                        Reset
                     </Button>
                  </Space>
               </Form.Item>
            </Form>
         </Modal>
      </>
   );
}

export default CmcKeyListPage;
