import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeIndex: "1",
};

export const activeIndexSlice = createSlice({
  name: "commonIndex",
  initialState,
  reducers: {
    setActiveIndex: (state, action) => {
      state.activeIndex = action.payload;
    },
  },
});

export const { setActiveIndex } = activeIndexSlice.actions;
export default activeIndexSlice.reducer;
