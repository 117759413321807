import { useEffect } from "react";

import { Button, Form, Modal, Select, Space } from "antd";
import { usePlansListRecordMutation } from "../../../../redux/services/plansListApi";
import { useUpgradePlanApiMutation } from "../../../../redux/services/venderApi";

const UpgradeVenderPlan = ({
   showUpgradePlanModal,
   setShowUpgradePlanModal,
   venderId,
   currentPlan,
   fetchVenderInfo
}) => {
   const [upgradePlanform] = Form.useForm();

   const [plansListRecord, { data: getPlansList }] = usePlansListRecordMutation();
   const [upgradePlanApi] = useUpgradePlanApiMutation();

   const onFinishUpgradePlanFrom = (values) => {
      values = {...values, venderId: venderId}

      upgradePlanApi(values).then((data) => {
         fetchVenderInfo({
            id: venderId
          });
         setShowUpgradePlanModal(false);
         upgradePlanform.resetFields();
      });
   };

   const onResetUpgradePlanFrom = () => {
      upgradePlanform.resetFields();
   };

   const options = []

   if (getPlansList?.data?.rows && Array.isArray(getPlansList?.data?.rows)) {
      for (let plan of getPlansList?.data?.rows) {
         options.push({
            value: plan.id,
            label: plan.title,
            disabled: plan.id == currentPlan?.id ? true : false
         })
      }
   }

   useEffect(() => {
      plansListRecord();
   }, [])

   return (
      <Modal
         id="logoutModal"
         centered
         open={showUpgradePlanModal}
         onOk={() => setShowUpgradePlanModal(false)}
         onCancel={() => setShowUpgradePlanModal(false)}
         width={600}
         okText={"Yes"}
         cancelText={"No"}
         footer={null}
      >

         <Form
            layout="vertical"
            form={upgradePlanform}
            name="upgradePlanForm"
            onFinish={onFinishUpgradePlanFrom}
         >
            <Form.Item
               name="planId"
               label="Select New Plan for upgrade"
               rules={[{ required: true }]}
            >
               <Select allowClear
                  placeholder="Select a new Plan for upgrade"
                  options={options}
               />
            </Form.Item>

            <Form.Item >
               <Space>
                  <Button type="primary" htmlType="submit">
                     Upgrade Now
                  </Button>
                  <Button htmlType="button" onClick={onResetUpgradePlanFrom}>
                     Reset
                  </Button>
               </Space>
            </Form.Item>

         </Form>
      </Modal>
   );
}
export default UpgradeVenderPlan;