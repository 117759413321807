import { useState } from "react";
import { useSelector } from "react-redux";

import { SyncOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Select, Space } from "antd";
import { useNavigate } from "react-router-dom";

import {
   useFilterOutTokenMutation
} from "../../../../redux/services/addTokenApi";


const { Option } = Select;

const ExtraButtonsCoinList = ({
}) => {
   const [searchTokenForm] = Form.useForm();

   const navigate = useNavigate();
   const [isModalOpen, setIsModalOpen] = useState(false);

   const [filterOutToken] = useFilterOutTokenMutation();

   const { tokenData } = useSelector((data) => data?.addTokenSlice);

   const onsearchTokenFromFinish = (values) => {
      if (values > 0 || values !== "") {
         filterOutToken(values)?.then((resp) => {
            if (resp?.data?.status) {
               setIsModalOpen(false);
               searchTokenForm.resetFields();
            }
         });
      }
   };

   const handleCancel = () => {
      setIsModalOpen(false);
   };

   const onReset = () => {
      searchTokenForm.resetFields();
   };

   const formRule = [{ required: true }];

   return (
      <>
         <Row gutter={[10, 10]}>
            <Col xs={24} sm={12} md={5} lg={9}>
               <Button type="primary" onClick={() => setIsModalOpen(true)}>
                  Add Token
               </Button>
            </Col>
            <Col xs={24} sm={12} md={10} lg={15}>
               <Button type="primary" onClick={() => navigate("/coins/sync")}>
                  <SyncOutlined /> Sync Coins Data
               </Button>
            </Col>
         </Row>

         <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            width={Object.keys(tokenData).length > 0 ? "50rem" : "35rem"}
            style={{ width: "300px" }}
         >
            <br />

            <Form layout='vertical' form={searchTokenForm} onFinish={onsearchTokenFromFinish} style={{ maxWidth: 600 }}>
               <Form.Item name="type" label="Type" rules={formRule}>
                  <Select placeholder="Select a option and change input text above" allowClear >
                     <Option value="slug"> Slug </Option>
                     <Option value="id"> Id </Option>
                     <Option value="address"> Contract Address </Option>
                  </Select>
               </Form.Item>

               <Form.Item name="value" label="Value" rules={formRule}>
                  <Input allowClear />
               </Form.Item>

               <Form.Item>
                  <Space>
                     <Button type="primary" htmlType="submit"> Search Now </Button>
                     <Button htmlType="button" onClick={onReset}> Reset </Button>
                  </Space>
               </Form.Item>
            </Form>
         </Modal>

      </>
   );
}
export default ExtraButtonsCoinList;
