import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};

const adminToken = localStorage.getItem("adminToken");
export const userSlice = createSlice({
  initialState,
  name: "userSlice",
  reducers: {
    logout: (state, action) => {
      action?.payload == null && localStorage.clear(adminToken);
      state.user = null;
    },
    setUser: (state, action) => {
      state.user = action?.payload?.data;
    },
  },
});

export default userSlice.reducer;

export const { logout, setUser } = userSlice.actions;
