import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../common/constant/constant";

import { toast } from "react-toastify";
import { logout } from "../../redux/slice/user.slice";
import { setToast } from "../slice/toast.slice";

export const settingsApi = createApi({
  reducerPath: "settingsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.ADMIN_API_URL,
    prepareHeaders: (headers, { getState, endpoints }) => {
      const { token } = getState()?.userState?.user;
      headers.set("Authorization", `${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    
    notificationSettingsData: builder.mutation({
      query: (payload) => ({
        method: "GET",
        url: `settings/notification/list`,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          let { data } = await queryFulfilled;
        } catch (error) {
          toast.error(error?.error?.data?.message, { autoClose: 2000 });

          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
    }),

    updateNotificationSettings: builder.mutation({
      query: (payload) => ({
        url: "settings/notification/update",
        method: "POST",
        body: payload,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.status) {
            toast.success(data?.message, { autoClose: 2000 });
          }
        } catch (error) {
          toast.error(error?.error?.data?.message, { autoClose: 2000 });

          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
      async onCacheEntryAdded(arg, { dispatch }) {},
    }),

    deviceList: builder.mutation({
      query: (payload) => ({
        method: "GET",
        url: "settings/deviceList",
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          let { data } = await queryFulfilled;
        } catch (error) {
          toast.error(error?.error?.data?.message, { autoClose: 2000 });

          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
    }),

    resetPassword: builder.mutation({
      query: (loginData) => ({
        url: "settings/security/changePassword",
        method: "POST",
        body: loginData,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          //  if (data?.status) {
          dispatch(
            setToast({
              message: data?.message,
              success: true,
              error: false,
            })
          );
          //  }
        } catch (error) {
          dispatch(
            setToast({
              message:
                error?.error?.data?.data[0]?.newPassword?.matches ||
                error?.error?.data?.message || "server error",
              success: false,
              error: true,
            })
          );
          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
      async onCacheEntryAdded(arg, { dispatch }) {},
    }),

    addSetting: builder.mutation({
      query: (addSettingData) => ({
        url: "settings/addSetting",
        method: "POST",
        body: addSettingData,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          //  if (data?.status) {
          dispatch(
            setToast({
              message: data?.message,
              success: true,
              error: false,
            })
          );
          //  }
        } catch (error) {
          dispatch(
            setToast({
              message:
                error?.error?.data?.data[0]?.newPassword?.matches ||
                error?.error?.data?.message || "server error",
              success: false,
              error: true,
            })
          );
          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
      async onCacheEntryAdded(arg, { dispatch }) {},
    }),

  }),
});

export const {
  useNotificationSettingsDataMutation,
  useUpdateNotificationSettingsMutation,
  useDeviceListMutation,
  useResetPasswordMutation,
  useAddSettingMutation
} = settingsApi;
