
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Card, Col, Empty, Segmented, Space } from 'antd';
import { useVenderHitCountDataMutation } from '../../../../redux/services/dashboardApi';

// Register the plugin
Chart.register(ChartDataLabels);

const defultVenderHitCountFilter = 'last1hour';

const segmentOptions = [{
   label: 'Last 1 Hour',
   value: 'last1hour',
}, {
   label: 'Today',
   value: 'currentDay',
}, {
   label: 'Last 24 Hours',
   value: 'last24hour',
}, {
   label: 'Current Month',
   value: 'currentMonth',
}];

const VenderHitCountsChart = () => {
   const [getVenderHitCountFilter, setVenderHitCountFilter] = useState();
   const [fetchVenderHitCount, { data: GetHtCounts }] = useVenderHitCountDataMutation();

   const venderList = GetHtCounts?.data?.venderHitCounts;


   // const dfs = [{
   //    name: "Cmc Vender 001",
   //    total_credit_count: "10"
   // }, {
   //    name: "Cmc Vender 002",
   //    total_credit_count: "45"
   // }, {
   //    name: "Cmc Vender 003",
   //    total_credit_count: "75"
   // }, {
   //    name: "Cmc Vender 004",
   //    total_credit_count: "101"
   // }, {
   //    name: "Cmc Vender 005",
   //    total_credit_count: "9"
   // }, {
   //    name: "Cmc Vender 006",
   //    total_credit_count: "90"
   // }, {
   //    name: "Cmc Vender 007",
   //    total_credit_count: "37"
   // }, {
   //    name: "Cmc Vender 008",
   //    total_credit_count: "86"
   // }, {
   //    name: "Cmc Vender 009",
   //    total_credit_count: "22"
   // }, {
   //    name: "Cmc Vender 0010",
   //    total_credit_count: "91"
   // }]

   const [graphData, setGraphData] = useState({
      label: [],
      value: [],
   });

   useEffect(() => {
      const venders = [];
      const hitCounts = [];
      venderList?.forEach((item) => {
         venders.push(item.name);
         hitCounts.push(item?.total_credit_count || 0);
      });
      setGraphData({ label: venders, value: hitCounts });

   }, [venderList]);

   useEffect(() => {
      setVenderHitCountFilter(defultVenderHitCountFilter);

      fetchVenderHitCount({
         filterType: defultVenderHitCountFilter
      });
   }, [defultVenderHitCountFilter]);

   const graphFianlData = {
      labels: graphData.label,
      datasets: [
         {
            label: 'HitCounts', // This label will be hidden
            data: graphData.value,
            backgroundColor: 'rgb(24, 144, 255, 0.5)',
            barThickness: 20, // Adjust the thickness of the bars
            maxBarThickness: 50, // Maximum thickness of the bars
            barPercentage: 0.8, // Adjust the width of the bars
            categoryPercentage: 1, // Adjust the spacing between bars
         },
      ],
   };

   const graphOptions = {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y', // This option makes the bar chart horizontal
      scales: {
         x: {
            beginAtZero: true,
         },
         y: {
            // display: false, // Hide the y-axis labels
         },
      },
      plugins: {
         legend: {
            display: false, // Hide the dataset label
         },
         datalabels: {
            anchor: 'end',
            align: 'end', /// start, end
            // color: '#999',
            // backgroundColor: 'rgb(24, 144, 255)',
            // formatter: (value) => value.toLocaleString(),
            formatter: (value, context) => {
               // const vender = graphData.label[context.dataIndex];
               return `${value.toLocaleString()}`; /// ${vender} (${value.toLocaleString()})
            },
         },
      },
   };

   const changeVenderHitCountFilter = (newValue) => {
      setVenderHitCountFilter(newValue);

      fetchVenderHitCount({
         filterType: newValue
      });
   }

   return (
      <>
         <Card
            style={{ minHeight: '400px' }}
            title="Most Api Credits Consumer"
            extra={
               <Space>
                  <Segmented
                     className='venderHitCountFilter'
                     name="venderHitCountFilter"
                     options={segmentOptions}
                     onChange={(value) => {
                        changeVenderHitCountFilter(value); // string
                     }}
                     defaultValue={defultVenderHitCountFilter}
                  />
               </Space>
            }
         >

            {
               venderList?.length > 0 ? (
                  <div
                     style={{ height: '300px' }}
                  >
                     <Bar data={graphFianlData} options={graphOptions} />
                  </div>
               ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
               )
            }
         </Card>
      </>
   );
};
export default VenderHitCountsChart;
