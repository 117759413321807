import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { Card, Col, List, Row } from "antd";

import "../../../index.scss"

import { useDashboardDataMutation } from "../../../redux/services/dashboardApi";

import CountersDashboard from "./component/conters.com";
import CmcKeysUsageDashboard from "./component/cmcKeysUsage.comp";

import ActionButtonsVenders from "../vender/component/actionBtns.comp";
import VenderHitCountsChart from "./component/venderHitCountsChart.comp";
import SubscriptionExpiring from "./component/subscriptionExpiring.comp";

const DashboardPage = () => {
  const [dashboardData, { data: GetDashboardData }] = useDashboardDataMutation();

  const counter = GetDashboardData?.data?.counter;

  const recentVenderRequest = GetDashboardData?.data?.recentVenderRequest;

  useEffect(() => {

    // if (count == 0) {
    //   dashboardData();
    //   setCount(1)
    // }
    // const time = 60000; /// milliseconds (since 1 minute = 60,000)
    // let interval = setInterval(() => {
    //   dashboardData();
    // }, (time * 5));
    // return () => { clearInterval(interval) }

    dashboardData();
  }, []);

  useEffect(() => {
  }, []);

  return (
    <>
      <Card
        className="dashboardPage"
        title=""
        extra={""}
        style={{
          border: "none",
          backgroundColor: "#f0f2f5",
          // height: "84vh",
        }}
      >

        <Row className="recentVender_Page" gutter={[14, 14]} style={{ paddingBottom: "12px" }} >
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <CountersDashboard counter={counter} />
          </Col>
        </Row>




        {/* /********************************* */}
        <Row gutter={[16, 16]} style={{ paddingBottom: "12px" }}>
          <Col className="gutter-row" xs={24} sm={24} md={24} lg={10} xl={6} xxl={5}>
            <CmcKeysUsageDashboard plan_type="Paid Plan Keys" cmcKeysUsage={GetDashboardData?.data?.cmcKeysUsage?.paid_plan} />
          </Col>

          <Col className="gutter-row" xs={24} sm={24} md={24} lg={10} xl={6} xxl={5}>
            <CmcKeysUsageDashboard plan_type="Free Plan Keys" cmcKeysUsage={GetDashboardData?.data?.cmcKeysUsage?.free_plan} />
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={24} lg={14} xl={12} xxl={14} >
            <VenderHitCountsChart />
          </Col>
        </Row>


        {/* ******************************** */}
        <Row className="recentVender_Page" gutter={[14, 14]} >
          {/* <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={8}>
            <CountersDashboard counter={counter} />
          </Col> */}


          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Card className="recentVenderRequestList" title="Recent Vender Request" extra={
              <>
                {recentVenderRequest?.length > 0 && (
                  <Link className="linkButton" to="/venders-list"> Show more  </Link>
                )}
              </>
            }
            >
              <List
                itemLayout="horizontal"
                dataSource={recentVenderRequest}
                renderItem={(item, index) => (
                  <List.Item className="">
                    <List.Item.Meta
                      title={item.name}
                      description={item.email}
                    />

                    <Row gutter={[10]}>
                      {item?.status == "pending" && (
                        <ActionButtonsVenders
                          vender={item}
                          dashboardData={dashboardData}
                        />
                      )}
                    </Row>
                  </List.Item>
                )}
              />
            </Card>
          </Col>

          <Col className='DefaultPage listStyle subscriptionExpiringList' xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <SubscriptionExpiring />

          </Col>

        </Row>

        {/* ******************************** */}

        {/* <Row gutter={[16, 16]} >
          <Col className='DefaultPage listStyle' xs={24} sm={24} md={24} lg={12} xl={9} xxl={8}>

          </Col>
        </Row> */}

      </Card>
    </>
  );
};
export default DashboardPage;
