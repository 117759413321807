import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tokenData: {},
};

export const addTokenSlice = createSlice({
  name: "token-data",
  initialState,
  reducers: {
    setTokenData: (state, action) => {
      state.tokenData = action.payload;
    },

    clearTokenData: (state, action) => {
      state.tokenData = action.payload;
    },
  },
});

export const { setTokenData, clearTokenData } = addTokenSlice.actions;
export default addTokenSlice.reducer;
