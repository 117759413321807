import React, { useEffect } from 'react';
import { Card, Col, List, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import "../../../../index.scss";
import { useSubsExpDataMutation } from '../../../../redux/services/dashboardApi';
import { convertAbbreviateNumber, statusColorCode } from '../../../../Helper/utility.helper';
import moment from 'moment';

const { Text } = Typography

const SubscriptionExpiring = () => {
   const [fetchSubsExpData, { data: SubsExpList }] = useSubsExpDataMutation();
   const venderList = SubsExpList?.data?.venders;

   useEffect(() => {
      fetchSubsExpData();
   }, []);
   return (
      <>
         <Card
            className='expiringSubscriptionCard'
            title="Expiring Subscriptions"
            extra={
               <Link className="linkButton" to="/venders-list">
                  Show more
               </Link>
            }
         >
            <List
               className='expiringSubscriptionList'
               itemLayout="horizontal"
               dataSource={venderList}
               renderItem={(item, index) => {
                  const alertCss = statusColorCode(item.plan_valid_up_to);
                  return (
                     // <Alert message={
                     <List.Item className="vender-list-item"
                        style={{
                           backgroundColor: alertCss.bgcolor,
                        }}>
                        <List.Item.Meta
                           title={<a href={'vender-details/'+item.id}>{item.name}</a>}
                           description={item.email}
                        />

                        <Row gutter={[10]}>
                           <Text className='width100 textAlignRight'>
                              <b> Expiring on:</b>
                              {item?.plan_valid_up_to ? moment.utc(item.plan_valid_up_to).format("DD-MMM-YYYY - HH:mm:ss A") : "N/A"}
                           </Text>
                           <Text className='width100 textAlignRight'>
                              <b> Plan:</b>
                              {item?.api_hits_plan?.title?.toUpperCase()}
                              ({convertAbbreviateNumber(item?.api_hits_plan?.monthly_hits || 0)})
                           </Text>
                        </Row>
                     </List.Item>
                  )
               }}
            />
         </Card>
      </>
   );
}
export default SubscriptionExpiring;

