import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import { Breadcrumb, Card, Pagination, Row } from "antd";
import ExtraButtonsCoinList from "./component/extraButtons.com";
import CoinsFilter from "./component/coinsFilter.com";
import CoinListCompo from "./component/coinsList.com";

import {
  useCoinsListMutation,
} from "../../../redux/services/coinsApi";

const defaultPageSize = 10;
const defaultCurrent = 1;

const CoinsListPage = () => {
  const location = useLocation();

  const [getCurrentPage, setCurrentPage] = useState();
  const [getPageSize, setPageSize] = useState();
  const [getStatus, setStatus] = useState('');
  const [getKeyword, setKeyword] = useState('');
  const [getOrderColumn, setOrderColumn] = useState('');
  const [getSortBy, setSortBy] = useState('');

  const [fetchCoinsList, { data: getCoinsList }] = useCoinsListMutation();
  const CoinsList = getCoinsList?.data;

  const CoinsCount = CoinsList?.count ? CoinsList?.count : 0;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status');

    let ss = getStatus || status
    fetchCoinsList({
      page: defaultCurrent,
      limit: defaultPageSize,
      status: getStatus,
      keyword: getKeyword,
      orderColumn: getOrderColumn,
      sortBy: getSortBy
    });

    setCurrentPage(defaultCurrent);
    setPageSize(defaultPageSize);
  }, [getStatus]);


  const onPageChange = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPageSize(pageSize);

    fetchCoinsList({
      page: pageNumber,
      limit: pageSize,
      status: getStatus,
      keyword: getKeyword,
      orderColumn: getOrderColumn,
      sortBy: getSortBy
    });
  };

  const reseteCoinSearchKeywordFn = () => {
    setStatus('');
    setKeyword('');

    fetchCoinsList({
      page: getCurrentPage,
      limit: getPageSize,
      status: getStatus,
      keyword: getKeyword,
      orderColumn: getOrderColumn,
      sortBy: getSortBy
    });
  };

  const coinSearchKeywordFn = (name, status, orderColumn, sortBy) => {
    setStatus(status);
    setKeyword(name);
    setOrderColumn(orderColumn);
    setSortBy(sortBy);

    fetchCoinsList({
      page: getCurrentPage,
      limit: getPageSize,
      status: status,
      keyword: name,
      orderColumn: orderColumn,
      sortBy: sortBy

    });
  };

  return (
    <>
      <Card style={{ marginBottom: '5px' }}>
        <Breadcrumb items={[
          { title: "Dashboard", },
          { title: "Coins List", },
        ]}
        />
      </Card>


      <Card
        title="All Coin List"
        className="coinlist_tocken"
        extra={
          <ExtraButtonsCoinList />
        }
        style={{
          border: "none",
          marginBottom: '3px'
        }}
      >
        <CoinsFilter
          reseteCoinSearchKeywordFn={reseteCoinSearchKeywordFn}
          coinSearchKeywordFn={coinSearchKeywordFn}
        />
      </Card>

      <CoinListCompo
        fetchCoinsList={fetchCoinsList}
        coinList={CoinsList?.rows}
        currentPage={getCurrentPage}
        pageSize={getPageSize}
        getStatus={getStatus}
        getKeyword={getKeyword}
        orderColumn={getOrderColumn}
        sortBy={getSortBy}
      />

      <Row className="PaginationRow justifyContentCenter">
        {CoinsCount >= 10 && (
          <Pagination
            showQuickJumper
            current={getCurrentPage}
            total={CoinsCount}
            pageSize={getPageSize}
            onChange={onPageChange}
            responsive={true}
          />
        )}
      </Row>

    </>
  );
}
export default CoinsListPage;
