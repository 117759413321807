import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../common/constant/constant";
import { logout } from "../slice/user.slice";
import { startLoading, stopLoading } from "../slice/commonLoading.slice";
import { setPlansListData } from "../slice/plansList.slice";
import { setToast } from "../slice/toast.slice";

export const plansListApi = createApi({
  reducerPath: "plans-list-api-service",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.ADMIN_API_URL,
    prepareHeaders: (headers, { getState, endpoints }) => {
      const { token } = getState()?.userState?.user;
      headers.set("Authorization", `${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({

    plansListRecord: builder.mutation({
      query: (payload) => {
        return {
          method: "get",
          url: `/key/plans/list`,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(startLoading(true));
          let { data } = await queryFulfilled;

          data?.status && dispatch(stopLoading(false));
          dispatch(setPlansListData({ data: data }));
        } catch (error) {
          dispatch(stopLoading(false));
          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
    }),

    addNewPlan: builder.mutation({
      query: (payload) => ({
        method: "post",
        url: "key/plans/add",
        body: payload,
      }),
      invalidatesTags: ["vender"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          let { data } = await queryFulfilled;

          dispatch(
            setToast({
              message: data?.message,
              success: true,
              error: false,
            })
          );
        } catch (error) {
          dispatch(stopLoading(false));
          dispatch(
            setToast({
              message: error?.error?.data?.message || "server error",
              success: false,
              error: true,
            })
          );
          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
    }),

    updatePlanStatus: builder.mutation({
      query: (payload) => ({
        method: "post",
        url: "key/plans/updateStatus",
        body: payload,
      }),
      invalidatesTags: ["vender"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          let { data } = await queryFulfilled;

          dispatch(
            setToast({
              message: data?.message,
              success: true,
              error: false,
            })
          );
        } catch (error) {
          dispatch(stopLoading(false));
          dispatch(
            setToast({
              message: error?.error?.data?.message || "server error",
              success: false,
              error: true,
            })
          );
          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
    }),

    editPlan: builder.mutation({
      query: (payload) => ({
        method: "post",
        url: "key/plans/edit",
        body: payload,
      }),
      invalidatesTags: ["vender"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          let { data } = await queryFulfilled;

          dispatch(
            setToast({
              message: data?.message,
              success: true,
              error: false,
            })
          );
        } catch (error) {
          dispatch(stopLoading(false));
          dispatch(
            setToast({
              message: error?.error?.data?.message || "server error",
              success: false,
              error: true,
            })
          );
          if (error?.error?.status == 401) {
            setTimeout(() => {
              dispatch(logout());
            }, 2000);
          }
        }
      },
    }),

    

  }),
});

export const { 
  usePlansListRecordMutation, 
  useAddNewPlanMutation,
  useUpdatePlanStatusMutation,
  useEditPlanMutation
} = plansListApi;
