import React, { useEffect } from "react";

import { Button, Card, Form, Input } from "antd";

import { useResetPasswordMutation } from "../../../redux/services/settingsApi";

import { useDispatch } from "react-redux";

const SecuritySettingsPage = () => {
  const [resetPassword, { data: user, isLoading }] = useResetPasswordMutation();

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (params) => {
    dispatch(resetPassword(params));
  };

  useEffect(() => {
    if (user?.message) {
      form.resetFields();
    }
  }, [user?.message]);

  return (
    <>
      <Card title="Change Password" style={{ maxWidth: "850px", width: "100%", margin: "auto" }}>
        <Form
          form={form}
          layout="vertical"
          name="register"
          onFinish={onFinish}
          style={{
            maxWidth: 800,
          }}
        >
          <Form.Item
            name="oldPassword"
            label="Password"
            rules={[
              {
                required: true,
                message: "Old Password is required.",
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: "New Password is required. ",
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Confirm Password is required.",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The confirm password that you entered do not match with new password!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button style={{ float: "right" }} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
export default SecuritySettingsPage;
