import { Progress } from "antd";

const ApiHitsUsageIcon = ({
   title, monthlyHits, planHits
}) => {

   const usagePercentage = ((monthlyHits / planHits) * 100) || 0;

   let iconColor;
   let status = "normal";
   if (usagePercentage >= 90) {
      iconColor = 'red';
      status = "exception";
   } else if (usagePercentage >= 75) {
      iconColor = 'yellow';
      status = "active";
   } else {
      iconColor = 'green';
      status = "success";
   }
   return (
      <>
         <span style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-start"
         }}>
            <span> <b>{title} : </b> </span>
            <span><Progress
               percent={usagePercentage.toFixed(2)}
               percentPosition={{
                  align: 'end',
                  type: 'inner',
               }}
               status={status}
               strokeColor={iconColor}
               size={[200, 15]}
               showInfo={true}
            /> </span> <span>  ({monthlyHits}/{planHits}) </span>
         </span>
      </>
   );
}
export default ApiHitsUsageIcon;