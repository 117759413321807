import { Button, Col, Flex, Space } from "antd";
import { useState } from "react";
import ConfirmModal from "./confirmModal.comp";
import { useVenderStatusUpdateMutation } from "../../../../redux/services/venderApi";

const ActionButtonsVenders = ({
   vender,
   fetchVenderList,
   selectedValue,
   currentPage,
   pageSize,
   fetchVenderInfo,
   dashboardData
}) => {
   const [venderStatusUpdate, { data }] = useVenderStatusUpdateMutation();

   const [userData, setUserData] = useState({
      status: "",
      id: "",
   });
   const [showModal, setShowModal] = useState(false);

   const userRegistrationFn = () => {
      setShowModal(false);
      delete userData?.text;

      venderStatusUpdate(userData).then((resp) => {
         resp?.data?.message && fetchVenderList &&
            fetchVenderList({
               status: selectedValue,
               keyword: "",
               page: currentPage,
               limit: pageSize,
            });

         resp?.data?.message && fetchVenderInfo &&
            fetchVenderInfo({
               id: vender.id
            });

         resp?.data?.message && dashboardData &&
         dashboardData();


      });
   };

   return (
      <>
         <Flex wrap gap="small">
            {!(vender?.status == "pending" || vender?.status == "declined") && (
               <>
                  <Col span={12}>
                     <Space>

                        {/* api plan value Re-Set/renew button */}
                        {(vender?.status == "active") && (
                           <Button
                              type="primary"
                              onClick={() => {
                                 setUserData({
                                    ...userData,
                                    status: "renew",
                                    id: vender?.id,
                                    text: "Re-Set",
                                    email: vender?.email,
                                    name: vender?.name,
                                 });
                                 setShowModal(true);
                              }}
                           >
                              Re-Set
                           </Button>
                        )}

                        {/* api key block/un-block button */}
                        <Button
                           type="primary" onClick={() => {
                              setUserData({
                                 ...userData,
                                 status: vender?.status == "active" ? "blocked" : "active",
                                 id: vender?.id,
                                 text: vender?.status == "active" ? "block" : "unblock",
                                 email: vender?.email,
                                 name: vender?.name,
                              });
                              setShowModal(true);
                           }}
                           style={{
                              backgroundColor: vender?.status == "active" ? "red" : "green",
                              color: "white",
                           }}
                        >
                           {vender?.status == "active" ? "Block" : "Unblock"}
                        </Button>

                     </Space>
                  </Col>
               </>
            )}


            {vender?.status == "pending" && (
               <>
                  <Button
                     style={{ backgroundColor: "green", color: "white" }}
                     type="primary"
                     onClick={() => {
                        setUserData({
                           ...userData,
                           text: "accept",
                           name: vender?.name,
                           email: vender?.email,
                           status: "active",
                           id: vender?.id,
                        });
                        setShowModal(true);
                     }}
                  >
                     Accept
                  </Button>

                  <Button
                     type="primary"
                     danger
                     onClick={() => {
                        setUserData({
                           ...userData,
                           text: "denied",
                           name: vender?.name,
                           email: vender?.email,
                           status: "declined",
                           id: vender?.id,
                        });

                        setShowModal(true);
                     }}
                  >
                     Denied
                  </Button>
               </>
            )}
         </Flex>

         <ConfirmModal
            showModal={showModal}
            setShowModal={setShowModal}
            userData={userData}
            onUpdate={userRegistrationFn}
         />
      </>
   );
}
export default ActionButtonsVenders;
